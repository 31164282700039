// 组织组档案
import { staring_use, isOrno } from '../../../api/common.js';
const organization_group = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 130, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true}, // 
        { headerName: '组织组编号', field: 'code', hide: false},
        { headerName: '组织组名称', field: 'name', hide: false},
        { headerName: '创建时间', field: 'gmtCreate', minWidth: 250, hide: false},
        { headerName: '修改时间', field: 'gmtModified', minWidth: 250, hide: false},
        { headerName: '修改人', field: 'updatorName', hide: false},
        { headerName: '数据来源', field: 'source', hide: false, cellRenderer: "filter_source"},
        { headerName: '状态', field: 'status', hide: false,cellRenderer: "filter_useStatu"},
        { headerName: '是否下发', field: 'isNeedSend', hide: false,cellRenderer: "filter_issue"},
        { headerName: '操作', field: 'operate', pinned: 'right' , cellRenderer: 'operate_element', hide: false, lockPosition: true},
    ],
    searchList: [
        {
            title: '组织组名称',
            value: '',
            key: 'name',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            title: '组织组编号',
            value: '',
            key: 'code',
            label_width: '100px',
            component: 'StextInput'
        },
        // {
        //     title: '创建查询时段',
        //     value: [],
        //     key: 'search_creatTime',
        //     label_width: '100px',
        //     component: 'Stime'
        // },
        // {
        //     title: '更新查询时段',
        //     value: [],
        //     key: 'search_updateTime',
        //     label_width: '100px',
        //     component: 'Stime'
        // },
        // {
        //     title: '状态',
        //     value: '',
        //     key: 'status',
        //     label_width: '100px',
        //     component: 'Sselect',
        //     select_list_key: 'staring_use'
        // },
    ],
    modalList: [
        {
            title: '组织组名称',
            value: '',
            key: 'name',
            is_required: true,
            component: 'StextInput'
        },
        {
            title: '组织组编号',
            value: '',
            key: 'code',
            is_required: true,
            component: 'StextInput'
        },
        {
            title: '状态',
            value: 1,
            key: 'status',
            component: 'Sselect',
            is_required: true,
            select_list: staring_use
        },
        {
            title: '是否下发',
            value: 1,
            key: 'isNeedSend',
            component: 'Sselect',
            is_required: true,
            select_list: isOrno
        },
    ],
};

export default organization_group;
