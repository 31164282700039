// 盘点单
const checkOrder = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 100, width: 100, pinned: 'left', hide: false, headerCheckboxSelection: true, checkboxSelection: true, cellRenderer: "filter_rowNumber"},
        { headerName: '单号', field: 'billNumber', hide: false, minWidth:200},
        { headerName: '单据日期', field: 'billDate', hide: false, minWidth:200},
        { headerName: '组织', field: 'organizationName', hide: false},
        { headerName: '账套', field: 'accsName', hide: false},
        { headerName: '职员', field: 'staffName', hide: false},
        { headerName: '仓库', field: 'warehouseName', hide: false},
        { headerName: '单据状态', field: 'billReturnState', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'bill_status_arr3'}},
        { headerName: '单据摘要', field: 'comment', hide: false, minWidth: 200},
        { headerName: '操作', field: 'operate', pinned: 'right' , minWidth: 0, width: 80 , cellRenderer: 'operate_element', hide: false, lockPosition: true},
    ],
    DetailColumnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true}, // 
        { headerName: '总部商品', field: 'itemHeadName', hide: false, minWidth: 200},
        { headerName: '商品', field: 'materielName', hide: false, minWidth: 200},
        { headerName: '库存单位', field: 'materielStockName', hide: false},
        { headerName: '实际账面数量', field: 'qty', hide: false},
        { headerName: '盘点数量', field: 'inventoryQty', hide: false},
        { headerName: '确认数量', field: 'confirmQty', hide: false},
        { headerName: '批号', field: 'batchNumber', hide: false},
        { headerName: '备注', field: 'comment', hide: false, minWidth:250},
    ],
    searchList: [
        {
            title: '单据编号',
            value: '',
            key: 'billNumber',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            label_title: '账套',
            value: '',
            show_value: '',
            key: 'accsCode',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'single',
            component: 'AccountInput'
        },
        {
            label_title: '组织',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'organizationId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'OrganizationInput'
        },
        {
            label_title: '部门',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'departmentId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'DepartmentInput'
        },
        {
            label_title: '仓库',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'warehouseId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'WarehouseInput'
        },
        {
            label_title: '职员',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'staffId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'StaffInput'
        },
        {
            title: '创建查询时段',
            value: [],
            key: 'search_creatTime',
            label_width: '100px',
            component: 'Stime'
        },
        {
            title: '修改查询时段',
            value: [],
            key: 'search_updateTime',
            label_width: '100px',
            component: 'Stime'
        },
    ],
};

export default checkOrder;