
const globalStyle = {
    "theme-color": '#357497',
    "menu-color": '#2c3e56',
    "menu-selected-color": '#4f6074',
    "input-width": "150px",
    "error-color": "#ff4d4f",
    "theme-color1": '#2daabd',
    "hint-color": '#d6868f',
}
export default globalStyle;
