import React from 'react';
import intl from 'react-intl-universal';
import { ConfigProvider } from 'antd'; // 引入ConfigProvider全局化配置
import zhCN from 'antd/es/locale/zh_CN';  // 引入中文包
import zh from '../locale/zh_CN';

function Intl (props){
    intl.init({
        currentLocale: 'zh',
        locales: {
            zh
        }
    });
    return (
        <ConfigProvider locale={zhCN}>
            {props.children}
        </ConfigProvider>
    );
};

export default Intl;
