import {
    createGlobalStyle
} from 'styled-components';
import globalStyle from '../assets/global-style';

export const GlobalStyle = createGlobalStyle `
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, theadf, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
    }
    html, body{
        background: #f2f3f4;;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    a{
        text-decoration: none;
        color: #fff;
    }
    .ag-header-cell{
        border-right: 1px solid #d9dce0;
    }
    .ag-theme-alpine .ag-header-cell-resize{
        opacity: 0;
    }
    // 全局设置modal样式css
    ._self_modal{
        .ant-modal-body{
            ._search{
                width: 200px;
                margin: 0 0 20px 0;
            }
            .channel_dettailTable_wrapper{
                width: 100%;
                height: 400px;
            }
            .dealer_dettailTable_wrapper{
                width: 100%;
                height: 40vh;
            }
        }
        ._modal_body{
            max-height: 51vh;
            overflow: auto;
            .selector_wrapper{
                margin: 0 0 20px 0;
                display: flex;
                ._label{
                    font-size: 14px;
                    line-height: 30px;
                }
                .selector_item{
                    margin: 0 15px;
                }
            }
            .describle_wrapper{
                margin: 20px 0;
                ._title{
                    margin: 10px 0;
                }
            }
            ._modal_item{
                display: flex;
                margin: 0 0 15px 0;
                .input_wrapper{
                    display: flex;
                    margin: 0 20px 0 0 ;
                    ._label{
                        font-size: 14px;
                        line-height: 30px;
                        text-align: right;
                    }
                    .ant-input{
                        width: ${globalStyle['input-width']};
                    }
                }
            }
            .func_title{
                font-size: 16px;
                color: ${globalStyle['theme-color']};
                font-weight: bolder;
                margin: 10px 0;
            }
            .img_content{
                display: flex;
                flex-wrap: wrap;
                .img_content_wrapper{
                    margin-right: 10px;
                    .images{
                        width: 200px;
                        height: 200px;
                    }
                }
            }
            .video_content{
                .video_wrapper{

                }
                .file_operate{
                    padding-top: 5px;
                }
            }
            .file_operate{
                padding: 0 5px 5px 5px;
                display: flex;
                justify-content: space-between;
                .iconfont{
                    padding-right: 5px;
                }
            }
            .other_file{
                .file_wrapper{
                    display: flex;
                    justify-content: space-between;
                    .file_message{

                    }
                }
            }
        }
        .footer{
            margin: 10px 0;
        }
        .ag-theme-alpine .ag-ltr .ag-header-select-all {
            margin-right: 0;
        }
    }
    ._self_file_modal{
        ._modal_body{
            max-height: 70vh;
            overflow: auto;
        }
    }
    .video-react .video-react-big-play-button{
        top: 50% !important;
        left: 50% !important;;
        transform: translate(-50%, -50%) !important;;
    }
    .Slabel_title{
        text-align: right;
        line-height: 30px;
        font-size: 14px;
    }
    .iconbitian{
        color: red;
    }
    .redColor{
        color: red;
    }
    .ThemeColor{
        color: ${globalStyle["theme-color"]};
    }
    .largeFont{
        font-size: 16px;
        font-weight: bold;
    }
    .unit_table_wrapper{
        width: 100%;
        height: 300px;
        margin: 10px 0;
        .iconfont{
            padding-right: 10px;
            cursor: pointer;
        }
    }
    .iconbianjimian{
        color: ${globalStyle["theme-color"]};
    }
    .iconicon_shanchu{
        color: red;
    }
    .icontianjia{
        color: #75b757;
    }
    .ag-theme-alpine >.ag-row-odd {
            background-color: red;
    }
    .global_setting{
        margin: 0 10px;
        font-size: 14px;
        
    }
    .setting_content{
        cursor: pointer;
        color: ${globalStyle['theme-color']};
    }
    .sroll_wrapper::-webkit-scrollbar {
            width: 7px;
    }

    .sroll_wrapper::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
    }

    .sroll_wrapper::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
    }
    .displayNone{
        display: none;
    }
    .hide_dom{
        opacity: 0;
        z-index: -1 !important;
    }
    .font_elipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .iconwenjian{
        color: #ecc243;
    }
    .iconqiyong{
        color: #75b757;
    }
    .cell_table_wrapper{
        width: 400px;
        height: 250px;
        margin: 10px 0;
    }
    .Modal_content{
        max-height: 50vh;
        overflow: auto;
    }
`