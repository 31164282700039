// 账套信息
import { order_types, isOrno } from '../../../api/common.js';
const account_information = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true}, // 
        { headerName: '账套名称', field: 'name', hide: false, minWidth: 180},
        { headerName: '账套编号', field: 'code', hide: false, minWidth: 180},
        { headerName: '组织名称', field: 'orgName', hide: false, minWidth: 180},
        { headerName: '组织编号', field: 'orgKey', hide: false, minWidth: 180},
        { headerName: '创建时间', field: 'gmtCreate', hide: false, minWidth: 250},
        { headerName: '修改时间', field: 'gmtModified', hide: false, minWidth: 250},
        { headerName: '单据格式', field: 'gridType', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'order_types_arr'}},
        { headerName: 'ERP版本', field: 'proinfo', hide: false},
        { headerName: '接口地址', field: 'apiAddress', hide: false, minWidth: 300},
        { headerName: '数据库服务器ID', field: 'serverIpAddress', hide: false, minWidth: 300},
        { headerName: '数据库名称', field: 'databaseName', hide: false, minWidth: 150},
        { headerName: '数据来源', field: 'source', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'data_origin'}},
        { headerName: '国家', field: 'countryName', hide: false},
        { headerName: '省', field: 'provName', hide: false},
        { headerName: '市', field: 'cityName', hide: false},
        { headerName: '区/县', field: 'counName', hide: false},
        { headerName: '详细地址', field: 'address', minWidth: 200, hide: false},
        { headerName: '是否是赛轮账套', field: 'slAccsCoded', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '是否启用批号', field: 'isOpenBatchNumber', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'staring_use_arr'}},
        { headerName: '状态', field: 'status', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'staring_use_arr'}},
        { headerName: '操作', field: 'operate', pinned: 'right' , cellRenderer: 'operate_element', hide: false, lockPosition: true},
        // { headerName: '操作', field: 'operate', pinned: 'right' , cellRenderer: 'table_operate', hide: false, lockPosition: true, cellRendererParams: {operate_list: [
        //     {
        //         title: '停用',
        //         function: 'edit',
        //         icon: 'iconbianjimian',
        //         class: '',
        //     },
        //     {
        //         title: '删除',
        //         function: 'delete',
        //         icon: 'iconicon_shanchu',
        //         class: '',
        //         popConfirm: true,
        //     },
        // ]}},
    ],
    searchList: [
        {
            title: '账套名称',
            value: '',
            key: 'name',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            title: '账套编号',
            value: '',
            key: 'code',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            label_title: '组织',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'orgCode',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'OrganizationInput'
        },
        {
            title: '创建查询时段',
            value: [],
            key: 'search_creatTime',
            label_width: '100px',
            component: 'Stime'
        },
        {
            title: '更新查询时段',
            value: [],
            key: 'search_updateTime',
            label_width: '100px',
            component: 'Stime'
        },
    ],
    modalList: [
        {
            title: '账套名称',
            value: '',
            key: 'name',
            is_required: true,
            component: 'StextInput'
        },
        {
            title: '账套编号',
            value: '',
            key: 'code',
            is_required: true,
            is_disabled: false,
            component: 'StextInput'
        },
        {
            title: '是否启用批号',
            value: 1,
            key: 'isOpenBatchNumber',
            component: 'Sselect',
            is_required: true,
            select_list: isOrno,
        },
        {
            label_title: '组织',
            value: '',
            show_value: '',
            key: 'orgKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            is_required: true,
            isRow_selection: true,
            label_width: '130px',
            selectType: 'single',
            component: 'OrganizationInput'
        },
        {
            title: '单据格式',
            value: 1,
            key: 'gridType',
            component: 'Sselect',
            select_list: order_types,
        },
        // {
        //     title: '是否下发',
        //     value: 1,
        //     key: 'isNeedSend',
        //     component: 'Sselect',
        //     is_required: true,
        //     select_list: isOrno,
        // },
        {
            title: '是否是赛轮账套',
            value: 0,
            key: 'slAccsCoded',
            component: 'Sselect',
            is_required: true,
            select_list: isOrno,
        },
        {
            title: '是否启用',
            value: 1,
            key: 'status',
            component: 'Sselect',
            is_required: true,
            select_list: isOrno,
        },
        {
            title: '联系人',
            value: '',
            key: 'linkman',
            component: 'StextInput'
        },
        {
            title: '电话',
            value: '',
            key: 'telephone',
            component: 'StextInput'
        },
        {
            title: 'ERP版本',
            value: '',
            key: 'proinfo',
            component: 'StextInput'
        },
        {
            title: '接口地址',
            value: '',
            key: 'apiAddress',
            width: '440px',
            component: 'StextInput'
        },
        {
            title: '数据库地址',
            value: '',
            key: 'serverIpAddress',
            width: '440px',
            component: 'StextInput'
        },
        {
            title: '数据库名称',
            value: '',
            key: 'databaseName',
            width: '440px',
            component: 'StextInput'
        },
        {
            label_title: '城市信息',
            value: '',
            key: 'cityMessage',
            label_width: '130px',
            width: '440px',
            component: 'CityInput',
            default_value: [],
        },
        {
            title: '详细地址',
            value: '',
            key: 'address',
            width: '440px',
            component: 'StextAreaInput'
        },
        {
            title: '备注',
            value: '',
            key: 'remark',
            width: '440px',
            component: 'StextAreaInput'
        },
    ],
};

export default account_information;