// 客户信息
const customer = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber" , lockPosition: true},
        { headerName: '客户编号', field: 'code', hide: false},
        { headerName: '客户名称', field: 'name', hide: false},
        { headerName: '账套编号', field: 'accsCode', hide: false},
        { headerName: '账套名称', field: 'accsName', hide: false, minWidth: 200},
        { headerName: '组织编号', field: 'orgCode', hide: false},
        { headerName: '组织名称', field: 'orgName', hide: false, minWidth: 200},
        { headerName: '邮编', field: 'postCode', hide: false},
        { headerName: '税号', field: 'taxNumber', hide: false},
        { headerName: '银行账号', field: 'bankAccount', hide: false},
        { headerName: '联系电话1', field: 'tel1', hide: false},
        { headerName: '联系电话2', field: 'tel2', hide: false},
        { headerName: '联系电话3', field: 'tel3', hide: false},
        { headerName: '联系人1', field: 'linkman', hide: false},
        { headerName: '联系人1电话', field: 'linkmanPhone', hide: false},
        { headerName: '联系人1地址', field: 'linkmanAddress', hide: false},
        { headerName: '省', field: 'provName', hide: false},
        { headerName: '市', field: 'cityName', hide: false},
        { headerName: '区/县', field: 'counName', hide: false},
        { headerName: '详细地址', field: 'address', hide: false},
        { headerName: '数据类型', field: 'isCustomer', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'customerType_arr'}},
        { headerName: '创建时间', field: 'gmtCreate', minWidth: 250, hide: false},
        { headerName: '修改时间', field: 'gmtModified', minWidth: 250, hide: false},
        { headerName: '修改人', field: 'updatorName', hide: false},
        { headerName: '关联职员', field: 'employeeName', hide: false},
        { headerName: '对应组织', field: 'orgName', hide: false},
        { headerName: '数据来源', field: 'source', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'data_origin'}},
        { headerName: '状态', field: 'status', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'staring_use_arr'}},
        { headerName: '是否下发', field: 'isNeedSend', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '备注', field: 'comment', hide: false, minWidth: 250},
    ],
    searchList: [
        {
            title: '客户名称',
            value: '',
            key: 'name',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            title: '客户编号',
            value: '',
            key: 'code',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            title: '创建查询时段',
            value: [],
            key: 'search_creatTime',
            label_width: '100px',
            component: 'Stime'
        },
        {
            title: '更新查询时段',
            value: [],
            key: 'search_updateTime',
            label_width: '100px',
            component: 'Stime'
        },
        {
            label_title: '账套',
            value: '',
            show_value: '',
            key: 'accsCodes',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'AccountInput'
        },
    ],
    modalList: [],
};

export default customer;
