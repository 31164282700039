
import * as types from './actionType';

// 创建DB
export const createDB = (value) => ({
    type: types.CREATE_DB,
    value
});
// 修改数据
export const changeData = (value) => ({
    type: types.CHANGE_DATA,
    value
});
// 修改加载状态
export const changeLoading = (value) => ({
    type: types.CHANGE_IS_LOADING,
    value
});
// 修改token
export const changeToken = (value) => ({
    type: types.CHANGE_TOCKEN,
    value
});
// 修改menu
export const changeMenu = (value) => ({
    type: types.CHANGE_MENU,
    value
});
// 修改组织
export const changeOrganization = (value) => ({
    type: types.CHANGE_ORGANIZATION,
    value
});
// 修改是否为开发者状态
export const changeIsDevelop = (value) => ({
    type: types.CHANGE_IS_DEVELOP,
    value
});
// 修改当前菜单
export const changeCurrentMenu = (value) => ({
    type: types.CHANGE_CURRENT_MENU,
    value
});
