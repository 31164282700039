import styled from 'styled-components';
import globalStyle from '../assets/global-style'

const CommonStyle = styled.div`
    .ant-btn{
        border-radius: 5px;
    }
    ._label{
        font-size: 14px;
        line-height: 30px;
        .iconbitian{
            color: red;
        }
    }
    .search_header{
        display: flex;
        .seach_input_wrapper{
            margin: 0 15px;
        }
    }
    .ant-input-affix-wrapper{
        padding: 0 11px;
    }
    .operate_wrapper{
        .iconfont{
            padding-right: 10px;
        }
        .iconbianjimian{
            color: ${globalStyle['theme-color']};
        }
        .iconicon_shanchu{
            color: red;
        }
        .icontianjia{
            color: #75b757;
        }
    }
    .Slable_title{
        font-size: 14px;
        line-height: 30px;
    }
    .iconbitian{
        color: red;
    }
    .footer{
        display: flex;
        justify-content: space-between;
    }
    .contentes_wrapper{
        background-color: white;
        padding: 20px;
        overflow: auto;
    }
    .order_detail_title{
        font-size: 14px;
        font-weight: bold;
        color: ${globalStyle['theme-color']};
        display: flex;
        justify-content: space-between;
        line-height: 30px;
        margin: 10px 0 0 0;
    }
    .operate_wrapper{
        .ant-select{
            width: 100%;
        }
    }
`;

export default CommonStyle;