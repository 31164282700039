// 销售出库单

const saleDeliveryOrder = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 100, width: 100, pinned: 'left', hide: false, headerCheckboxSelection: true, checkboxSelection: true, cellRenderer: "filter_rowNumber"},
        { headerName: '单号', field: 'billNumber', hide: false, minWidth:250},
        { headerName: '原销售订单单号', field: 'sourceBillNumber', hide: false, minWidth: 250},
        { headerName: '单据日期', field: 'billDate', hide: false, minWidth:200},
        { headerName: '组织', field: 'organizationName', hide: false},
        { headerName: '账套', field: 'accsName', hide: false},
        { headerName: '往来单位', field: 'customerName', hide: false},
        { headerName: '往来余额', field: 'accountsReceivable', hide: false},
        { headerName: '职员', field: 'staffName', hide: false},
        { headerName: '仓库', field: 'warehouseName', hide: false},
        { headerName: '部门', field: 'departmentName', hide: false},
        { headerName: '结算客户', field: 'jbtypename', hide: false},
        { headerName: '制单人', field: 'inetypename', hide: false},
        { headerName: '修改人', field: 'upetypename', hide: false},
        { headerName: '送达方地址', field: 'deliveryPartAddress', hide: false},
        { headerName: '车牌号', field: 'licensePlate', hide: false},
        { headerName: '司机名称', field: 'driver', hide: false},
        { headerName: '司机联系方式', field: 'driverTel', hide: false},
        { headerName: '交货时间', field: 'reachdate', hide: false},
        { headerName: '单据状态', field: 'billReturnState', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'bill_status_arr2'}},
        { headerName: '订单类型', field: 'orderType', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'order_type_arr'}},
        { headerName: '营销类型', field: 'marketType', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'marketing_type_arr'}},
        { headerName: '联系人', field: 'contact', hide: false},
        { headerName: '联系电话', field: 'contactTel', hide: false},
        { headerName: '区域', field: 'region', hide: false},
        { headerName: '运单号', field: 'wayBill', hide: false},
        { headerName: '车辆状态', field: 'busState', hide: false, cellRenderer: "filter_car_status_show"},
        { headerName: '单据摘要', field: 'comment', hide: false, minWidth: 200},
        { headerName: '备注', field: 'remarks', hide: false, minWidth: 200},
        { headerName: '操作', field: 'operate', pinned: 'right' , minWidth: 0, width: 80 , cellRenderer: 'operate_element', hide: false, lockPosition: true},
    ],
    DetailColumnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true}, // 
        { headerName: '商品', field: 'materielName', hide: false, minWidth: 200},
        { headerName: '品级', field: 'grade', hide: false},
        { headerName: '年限', field: 'years', hide: false},
        { headerName: '重量', field: 'weight', hide: false},
        { headerName: '销售单位', field: 'materielOrderSaleName', hide: false},
        { headerName: '销售单位数量', field: 'materielOrderSaleQuantity', hide: false},
        { headerName: '税率', field: 'taxRate', hide: false},
        { headerName: '折扣率', field: 'discount', hide: false},
        { headerName: '含税单价', field: 'priceTaxIncluding', hide: false},
        { headerName: '价税合计', field: 'priceTaxTotal', hide: false},
        { headerName: '税额', field: 'tax', hide: false},
        { headerName: '批号', field: 'batchNumber', hide: false},
        { headerName: '状态', field: 'state', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'marketing_type_arr'}},
        { headerName: '销售单位不含税单价', field: 'price', hide: false, minWidth: 160},
        { headerName: '销售单位不含税总价', field: 'totalprice', hide: false, minWidth: 160},
        { headerName: '销售单位折扣单价', field: 'discountprice', hide: false, minWidth: 150},
        { headerName: '销售单位折扣总价', field: 'discounttotalprice', hide: false, minWidth: 150},
        { headerName: '备注', field: 'comment', hide: false, minWidth:250},
        { headerName: '是否赠品', field: 'isgift', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
    ],
    searchList: [
        {
            title: '单据编号',
            value: '',
            key: 'billNumber',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            label_title: '账套',
            value: '',
            show_value: '',
            key: 'accsCode',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'single',
            component: 'AccountInput'
        },
        {
            label_title: '客户',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'customerId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'CustomerInput'
        },
        {
            label_title: '部门',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'departmentId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'DepartmentInput'
        },
        {
            label_title: '仓库',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'warehouseId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'WarehouseInput'
        },
        {
            label_title: '职员',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'staffId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'StaffInput'
        },
        {
            title: '创建查询时段',
            value: [],
            key: 'search_creatTime',
            label_width: '100px',
            component: 'Stime'
        },
        {
            title: '修改查询时段',
            value: [],
            key: 'search_updateTime',
            label_width: '100px',
            component: 'Stime'
        },
    ],
};

export default saleDeliveryOrder;
