// 付款单
import { bill_status } from '../../../api/common';

const paymentOrder = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 80, width: 80, pinned: 'left', hide: false, headerCheckboxSelection: true, checkboxSelection: true},
        { headerName: '单据编号', field: 'Billcode', hide: false},
        { headerName: '其它系统编号', field: 'Secondcode', hide: false},
        { headerName: '单据日期', field: 'Billdate', hide: false},
        { headerName: '接入时间', field: 'Createtime', hide: false},
        { headerName: '经销商', field: 'Stypename', hide: false},
        { headerName: '客户', field: 'Btypename', hide: false},
        { headerName: '结算客户', field: 'JBtypename', hide: false},
        { headerName: '部门', field: 'Dtypename', hide: false},
        { headerName: '职员', field: 'Etypename', hide: false},
        { headerName: '制单人', field: 'Inetypename', hide: false},
        { headerName: '修改人', field: 'Upetypename', hide: false},
        { headerName: '总金额', field: 'Totalmoney', hide: false},
        { headerName: '单据状态', field: 'ordertatus', hide: false},
        { headerName: '状态更改时间', field: 'Updatestatus', hide: false},
        { headerName: '下发时间', field: 'Sendtime', hide: false},
        { headerName: '中台修改人', field: 'Updateemployeename', hide: false},
        { headerName: '单据来源', field: 'source', hide: false},
        { headerName: '单据摘要', field: 'Comment', hide: false, minWidth: 200},
        { headerName: '单据说明', field: 'Explain', hide: false, minWidth: 200},
    ],
    DetailColumnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 80, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true}, // 
        { headerName: '付款账户编号', field: 'Atypeusercode', hide: false},
        { headerName: '付款账户名称', field: 'Atypeusername', hide: false},
        { headerName: '付款金额', field: 'Unitname', hide: false},
        { headerName: '确认数量', field: 'confirmQty', hide: false},
        { headerName: '状态', field: 'Status', hide: false},
    ],
    searchList: [
        {
            title: '单据编号',
            value: '',
            key: 'Usercode',
            component: 'StextInput'
        },
        {
            label_title: '经销商',
            value: '',
            show_value: '001',
            key: 'Stypekey',
            show_key: 'Username',
            is_auto: false,
            isRow_selection: true,
            selectType: 'multiple',
            component: 'DealerInput'
        },
        {
            title: '查询时段',
            value: ['2021-1-1', '2021-4-29'],
            key: 'search_time',
            component: 'Stime'
        },
        {
            title: '单据状态',
            value: 1,
            key: 'IfGoodsNumber',
            component: 'Sselect',
            select_list: bill_status
        },
    ],
};

export default paymentOrder;