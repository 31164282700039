// 组织架构
import { staring_use, isOrno } from '../../../api/common.js';
const organization_framework = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true},
        { headerName: '组织架构编号', field: 'code', hide: false},
        { headerName: '组织架构名称', field: 'name', hide: false},
        { headerName: '组织组编号', field: 'orgteamCode', hide: true},
        { headerName: '组织组名称', field: 'orgteamName', hide: false},
        { headerName: '组织编号', field: 'orgCode', hide: true},
        { headerName: '组织名称', field: 'orgName', hide: false},
        { headerName: '渠道编号', field: 'channelCode', hide: true},
        { headerName: '渠道名称', field: 'channelName', hide: false},
        { headerName: '分公司编号', field: 'branchCode', hide: true},
        { headerName: '分公司名称', field: 'branchName', hide: false},
        { headerName: '大区编号', field: 'regionCode', hide: true},
        { headerName: '大区名称', field: 'regionName', hide: false},
        { headerName: '办事处编号', field: 'agencyCode', hide: true},
        { headerName: '办事处名称', field: 'agencyName', hide: false},
        { headerName: '创建时间', field: 'gmtCreate', hide: false},
        { headerName: '修改时间', field: 'gmtModified', hide: false},
        { headerName: '修改人', field: 'updatorName', hide: false},
        { headerName: '数据来源', field: 'source', hide: false, cellRenderer: "filter_source"},
        { headerName: '状态', field: 'status', hide: false,cellRenderer: "filter_useStatu"},
        { headerName: '是否下发', field: 'isNeedSend', hide: false,cellRenderer: "filter_issue"},
        { headerName: '操作', field: 'operate', pinned: 'right' , cellRenderer: 'operate_element', hide: false, lockPosition: true},
    ],
    searchList: [
        {
            title: '组织架构名称',
            value: '',
            key: 'name',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            title: '组织架构编号',
            value: '',
            key: 'code',
            label_width: '100px',
            component: 'StextInput'
        },
        // {
        //     title: '创建查询时段',
        //     value: [],
        //     key: 'search_creatTime',
        //     label_width: '100px',
        //     component: 'Stime'
        // },
        // {
        //     title: '更新查询时段',
        //     value: [],
        //     key: 'search_updateTime',
        //     label_width: '100px',
        //     component: 'Stime'
        // },
        // {
        //     title: '启用状态',
        //     value: 1,
        //     key: 'status',
        //     label_width: '100px',
        //     component: 'Sselect',
        //     select_list: staring_use
        // },
        {
            label_title: '渠道',
            value: '',
            show_value: '',
            key: 'channelKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'ChannelInput'
        },
        {
            label_title: '分公司',
            value: '',
            show_value: '',
            key: 'branchKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'CompanyInput'
        },
        {
            label_title: '大区',
            value: '',
            show_value: '',
            key: 'regionKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'RegionInput'
        },
        {
            label_title: '办事处',
            value: '',
            show_value: '',
            key: 'agencyKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'OfficeInput'
        },
        {
            label_title: '组织组',
            value: '',
            show_value: '',
            key: 'teamKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'OrganizationGroupInput'
        },
    ],
    modalList: [
        {
            title: '组织架构名称',
            value: '',
            key: 'name',
            is_required: true,
            component: 'StextInput'
        },
        {
            title: '组织架构编号',
            value: '',
            key: 'code',
            is_required: true,
            component: 'StextInput'
        },
        {
            label_title: '组织',
            value: '',
            show_value: '',
            key: 'orgKey',
            show_key: 'name',
            data_key: 'ukey',
            is_required: true,
            is_refresh: false,
            label_width: '120px',
            component: 'OrganizationInput'
        },
        {
            label_title: '渠道',
            value: '',
            show_value: '',
            key: 'channelKey',
            data_key: 'ukey',
            is_required: true,
            is_refresh: false,
            show_key: 'name',
            label_width: '120px',
            component: 'ChannelInput'
        },
        {
            label_title: '分公司',
            value: '',
            show_value: '',
            key: 'branchKey',
            data_key: 'ukey',
            show_key: 'name',
            is_required: true,
            is_refresh: false,
            label_width: '120px',
            component: 'CompanyInput'
        },
        {
            label_title: '大区',
            value: '',
            show_value: '',
            key: 'regionKey',
            show_key: 'name',
            data_key: 'ukey',
            is_required: true,
            is_refresh: false,
            label_width: '120px',
            component: 'RegionInput'
        },
        {
            label_title: '办事处',
            value: '',
            show_value: '',
            key: 'agencyKey',
            show_key: 'name',
            data_key: 'ukey',
            is_required: true,
            is_refresh: false,
            label_width: '120px',
            component: 'OfficeInput'
        },
        {
            label_title: '组织组',
            value: '',
            show_value: '',
            key: 'teamKey',
            show_key: 'name',
            data_key: 'ukey',
            is_required: true,
            is_refresh: false,
            label_width: '120px',
            component: 'OrganizationGroupInput'
        },
        {
            title: '是否下发erp',
            value: 1,
            key: 'isNeedSend',
            component: 'Sselect',
            is_required: true,
            select_list: isOrno
        },
        {
            title: '状态',
            value: 1,
            key: 'status',
            component: 'Sselect',
            is_required: true,
            select_list: staring_use
        },
    ],
};

export default organization_framework;