import styled from 'styled-components';

const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
    .search{
        border-radius: 5px;
        background-color: #f1f1f1;
        height: 50px;
        width: 30%;
    }
    .table{
        margin: 20px 0;
        border-radius: 5px;
        background-color: #f1f1f1;
        height: 65vh;
    }
    .footer{
        border-radius: 5px;
        background-color: #f1f1f1;
        height: 50px;
        width: 50%;
    }
`;

export default LoadingWrapper;