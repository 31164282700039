// 组织信息
const organization = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 70, width:70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true}, // 
        { headerName: '组织名称', field: 'name', hide: false, minWidth: 180},
        { headerName: '组织编号', field: 'code', hide: false},
        { headerName: 'appId(TMS)', field: 'appId', hide: false},
        { headerName: 'appSecret(TMS)', field: 'appSecret', hide: false},
        // { headerName: '账套名称', field: 'accsName', hide: false, minWidth: 180},
        // { headerName: '账套编号', field: 'accsCode', hide: false},
        { headerName: '创建时间', field: 'gmtCreate', hide: false, minWidth: 250},
        { headerName: '修改时间', field: 'gmtModified', hide: false, minWidth: 250},
        // { headerName: '状态', field: 'status', hide: false,cellRenderer: "filter_useStatu"},
        { headerName: '操作', field: 'operate', pinned: 'right' , cellRenderer: 'operate_element', hide: false, lockPosition: true},
    ],
    searchList: [
        {
            title: '组织名称',
            value: '',
            key: 'name',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            title: '组织编号',
            value: '',
            key: 'code',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            title: '创建查询时段',
            value: [],
            key: 'search_creatTime',
            label_width: '100px',
            component: 'Stime'
        },
        {
            title: '更新查询时段',
            value: [],
            key: 'search_updateTime',
            label_width: '100px',
            component: 'Stime'
        },
    ],
    modalList: [
        {
            title: '组织名称',
            value: '',
            key: 'name',
            is_required: true,
            component: 'StextInput'
        },
        {
            title: '组织编号',
            value: '',
            key: 'code',
            is_required: true,
            is_disabled: false,
            component: 'StextInput'
        },
        {
            title: 'appId(TMS)',
            value: '',
            key: 'appId',
            component: 'StextInput'
        },
        {
            title: 'appSecret(TMS)',
            value: '',
            key: 'appSecret',
            component: 'StextInput'
        },
        // {
        //     title: '是否启用',
        //     value: 1,
        //     key: 'status',
        //     component: 'Sselect',
        //     select_list_key: 'isOrno',
        // },
    ],
};

export default organization;
