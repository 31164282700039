// 同价调拨单
import { bill_status } from '../../../api/common';

const coequalTransferOrder = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 90, pinned: 'left', hide: false, headerCheckboxSelection: true, checkboxSelection: true, cellRenderer: "filter_rowNumber"},
        { headerName: '单号', field: 'billNumber', hide: false},
        { headerName: '其它系统编号', field: 'secondCode', hide: false},
        { headerName: '单据日期', field: 'billDate', hide: false},
        { headerName: '入库单编号', field: 'inWareHouseNumber', hide: false},
        { headerName: '入库单名称', field: 'inWareHoseName', hide: false},
        { headerName: '出库单编号', field: 'outWarehouseNumber', hide: false},
        { headerName: '出库单名称', field: 'outWarehouseName', hide: false},
        { headerName: '组织', field: 'organizationName', hide: false},
        { headerName: '来源组织', field: 'sourceOrganizationName', hide: false},
        { headerName: '部门', field: 'departmentName', hide: false},
        { headerName: '职员', field: 'staffName', hide: false},
        { headerName: '制单人', field: 'inetypename', hide: false},
        { headerName: '修改人', field: 'upetypename', hide: false},
        { headerName: '交货日期', field: 'reachdate', hide: false},
        { headerName: '单据状态', field: 'billReturnState', hide: false, cellRenderer: "filter_order_statu"},
        { headerName: '结算状态', field: 'settlementState', hide: false, cellRenderer: "filter_settlement_status"},
        { headerName: '单据来源', field: 'billSource', hide: false, cellRenderer: "filter_order_source"},
        { headerName: '单据摘要', field: 'comment', hide: false, minWidth: 200},
        { headerName: '单据说明', field: 'remarks', hide: false, minWidth: 200},
        { headerName: '操作', field: 'operate', pinned: 'right' , minWidth: 0, width: 80 , cellRenderer: 'operate_element', hide: false, lockPosition: true},
    ],
    DetailColumnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true}, // 
        { headerName: '出入库类型', field: 'materielName', hide: false},
        { headerName: '商品', field: 'materielName', hide: false},
        { headerName: '采购单位', field: 'materielOrderSaleName', hide: false},
        { headerName: '采购单位数量', field: 'materielOrderSaleQuantity', hide: false},
        { headerName: '税率', field: 'taxRate', hide: false},
        { headerName: '折扣率', field: 'discount', hide: false},
        { headerName: '价税合计', field: 'priceTaxTotal', hide: false},
        { headerName: '含税单价', field: 'priceTaxIncluding', hide: false},
        { headerName: '税额', field: 'tax', hide: false},
        { headerName: '状态', field: 'state', hide: false},
        { headerName: '批号', field: 'batchNumber', hide: false},
        { headerName: '基本单位未税单价', field: 'price', hide: false, minWidth: 150},
        { headerName: '基本单位未税总价', field: 'totalprice', hide: false, minWidth: 150},
        { headerName: '基本单位折扣单价', field: 'discountprice', hide: false, minWidth: 150},
        { headerName: '基本单位折扣总价', field: 'discounttotalprice', hide: false, minWidth: 150},
        { headerName: '成本单价', field: 'unitcost', hide: false, minWidth: 150},
        { headerName: '是否赠品', field: 'isgift', hide: false, cellRenderer: "filter_isgift"},
        { headerName: '确认数量', field: 'confirmQty', hide: false},
        { headerName: '备注', field: 'comment', hide: false},
        { headerName: '批号', field: 'batchNumber', hide: false},
        
    ],
    searchList: [
        {
            title: '单据编号',
            value: '',
            key: 'billNumber',
            component: 'StextInput'
        },
        {
            title: '查询时段',
            value: [],
            key: 'search_creatTime',
            label_width: '100px',
            component: 'Stime'
        },
        // {
        //     title: '更新查询时段',
        //     value: [],
        //     key: 'search_updateTime',
        //     label_width: '100px',
        //     component: 'Stime'
        // },
        {
            label_title: '组织',
            value: '',
            show_value: '',
            key: 'organizationId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'OrganizationInput'
        },
        // {
        //     label_title: '客户',
        //     value: '',
        //     show_value: '',
        //     key: 'customerId',
        //     show_key: 'name',
        //     data_key: 'ukey',
        //     is_auto: false,
        //     isRow_selection: true,
        //     label_width: '100px',
        //     selectType: 'multiple',
        //     component: 'CustomerInput'
        // },
        // {
        //     label_title: '部门',
        //     value: '',
        //     show_value: '',
        //     key: 'departmentId',
        //     show_key: 'name',
        //     data_key: 'ukey',
        //     is_auto: false,
        //     isRow_selection: true,
        //     label_width: '100px',
        //     selectType: 'multiple',
        //     component: 'DepartmentInput'
        // },
        // {
        //     label_title: '仓库',
        //     value: '',
        //     show_value: '',
        //     key: 'warehouseId',
        //     show_key: 'name',
        //     data_key: 'ukey',
        //     is_auto: false,
        //     isRow_selection: true,
        //     label_width: '100px',
        //     selectType: 'multiple',
        //     component: 'WarehouseInput'
        // },
        // {
        //     label_title: '职员',
        //     value: '',
        //     show_value: '',
        //     key: 'staffId',
        //     show_key: 'name',
        //     data_key: 'ukey',
        //     is_auto: false,
        //     isRow_selection: true,
        //     label_width: '100px',
        //     selectType: 'multiple',
        //     component: 'StaffInput'
        // },
        {
            title: '单据状态',
            value: 1,
            key: 'IfGoodsNumber',
            component: 'Sselect',
            label_width: '100px',
            select_list: bill_status
        },
    ],
};

export default coequalTransferOrder;