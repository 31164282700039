// 销售入库单
import { bill_status } from '../../../api/common';

const saleInStorageOrder = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 90, pinned: 'left', hide: false, headerCheckboxSelection: true, checkboxSelection: true},
        { headerName: '单据编号', field: 'Billcode', hide: false},
        { headerName: '其它系统编号', field: 'Secondcode', hide: false},
        { headerName: '单据日期', field: 'Billdate', hide: false},
        { headerName: '接入时间', field: 'Createtime', hide: false},
        { headerName: '经销商', field: 'Stypename', hide: false},
        { headerName: '客户', field: 'Btypename', hide: false},
        { headerName: '结算客户', field: 'JBtypename', hide: false},
        { headerName: '部门', field: 'Dtypename', hide: false},
        { headerName: '仓库', field: 'Ktypename', hide: false},
        { headerName: '职员', field: 'Etypename', hide: false},
        { headerName: '制单人', field: 'Inetypename', hide: false},
        { headerName: '修改人', field: 'Upetypename', hide: false},
        { headerName: '总数量', field: 'Totalqty', hide: false},
        { headerName: '含税总金额', field: 'Totalmoney', hide: false},
        { headerName: '单据状态', field: 'ordertatus', hide: false},
        { headerName: '状态更改时间', field: 'Updatestatus', hide: false},
        { headerName: '下发时间', field: 'Sendtime', hide: false},
        { headerName: '中台修改人', field: 'Updateemployeename', hide: false},
        { headerName: '单据来源', field: 'source', hide: false},
        { headerName: '单据摘要', field: 'Comment', hide: false, minWidth: 200},
        { headerName: '单据说明', field: 'Explain', hide: false, minWidth: 200},
    ],
    DetailColumnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 80, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true}, // 
        { headerName: '总部商品', field: 'itemHeadName', hide: false, minWidth: 200},
        { headerName: '商品', field: 'Stypename', hide: false},
        { headerName: '基本单位', field: 'Unitname', hide: false},
        { headerName: '基本单位数量', field: 'QTY', hide: false},
        { headerName: '基本单位单价', field: 'Price', hide: false},
        { headerName: '基本单位总价', field: 'Totalprice', hide: false},
        { headerName: '基本单位含税单价', field: 'Taxprice', hide: false, minWidth: 150},
        { headerName: '基本单位含税总价', field: 'Taxtotalprice', hide: false, minWidth: 150},
        { headerName: '基本单位折扣单价', field: 'Discountprice', hide: false, minWidth: 150},
        { headerName: '基本单位折扣总价', field: 'Discounttotalprice', hide: false, minWidth: 150},
        { headerName: '税率', field: 'Tax', hide: false},
        { headerName: '扣率', field: 'Discount', hide: false},
        { headerName: '是否赠品', field: 'Isgift', hide: false},
        { headerName: '批号', field: 'Goodsnumber', hide: false},
        { headerName: '确认数量', field: 'confirmQty', hide: false},
        { headerName: '备注', field: 'Comment', hide: false},
        { headerName: '状态', field: 'Status', hide: false},
    ],
    searchList: [
        {
            title: '单据编号',
            value: '',
            key: 'billNumber',
            component: 'StextInput'
        },
        {
            title: '查询时段',
            value: [],
            key: 'search_time',
            component: 'Stime'
        },
        {
            label_title: '组织',
            value: '',
            show_value: '',
            key: 'categoryKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'OrganizationInput'
        },
        {
            title: '单据状态',
            value: 1,
            key: 'IfGoodsNumber',
            component: 'Sselect',
            select_list: bill_status
        },
    ],
};

export default saleInStorageOrder;
