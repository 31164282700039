// 日志管理

const Log = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 90, width: 90, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber"},
        { headerName: '调用系统', field: 'sourceSystem', hide: false},
        { headerName: '目标系统', field: 'targetSystem', hide: false},
        { headerName: '接口地址', field: 'address', hide: false, minWidth: 300},
        { headerName: '请求服务', field: 'serviceName', hide: false, minWidth: 200},
        { headerName: '请求方式', field: 'method', hide: false},
        { headerName: '请求时间', field: 'requestTime', hide: false, minWidth: 200},
        { headerName: '响应时间', field: 'responseTime', hide: false, minWidth: 200},
        { headerName: '持续时长', field: 'duration', hide: false},
        { headerName: 'HTTP响应状态', field: 'httpStatus', hide: false},
        { headerName: '状态', field: 'status', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'operate_statu_arr'}},
        { headerName: '状态描述', field: 'remark', hide: false, minWidth: 200},
        { headerName: '请求参数', field: 'requestData', hide: false, minWidth: 400, cellRenderer: "cellClick"},
        { headerName: '响应结果', field: 'responseData', hide: false, minWidth: 400, cellRenderer: "cellClick"},
    ],
    searchList: [
        {
            title: '调用系统',
            value: '',
            key: 'sourceSystem',
            component: 'Sselect',
            select_list_key: 'use_system',
        },
        {
            title: '目标系统',
            value: '',
            key: 'targetSystem',
            component: 'Sselect',
            select_list_key: 'use_system',
        },
        {
            title: '状态',
            value: '',
            key: 'status',
            component: 'Sselect',
            select_list_key: 'operate_statu',
        },
        {
            title: '请求参数',
            value: '',
            key: 'requestData',
            component: 'StextInput'
        },
        {
            title: '响应参数',
            value: '',
            key: 'responseData',
            component: 'StextInput'
        },
        {
            title: '查询时段',
            value: [],
            key: 'search_creatTime',
            component: 'Stime'
        },
    ],
};

export default Log;