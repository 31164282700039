import React from 'react';
import LoadingWrapper from '../style/components/loading.style'

function Loading(){
    return(
        <LoadingWrapper>
            <div className="search"></div>
            <div className="table"></div>
            <div className="footer"></div>
        </LoadingWrapper>
    );
};

export default Loading;
