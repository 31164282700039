
// 基础信息
import Department from './zh_CN_Data/basic_information/department';
import Staff from './zh_CN_Data/basic_information/staff';
import Warehouse from './zh_CN_Data/basic_information/warehouse';
import Customer from './zh_CN_Data/basic_information/customer';
import GoodsUnit from './zh_CN_Data/basic_information/goods_unit';
import Organization from './zh_CN_Data/basic_information/organization';
import Account from './zh_CN_Data/basic_information/account';
import OrganizationGroup from './zh_CN_Data/basic_information/organization_group';
import OrganizationFramework from './zh_CN_Data/basic_information/organization_framework';
import Office from './zh_CN_Data/basic_information/office';
import GoodsClasses from './zh_CN_Data/basic_information/goods_classes';
import GoodsLargeClasses from './zh_CN_Data/basic_information/goods_largeClasses';
import GoodsSmallClasses from './zh_CN_Data/basic_information/goods_smallClasses';
import GoodsRecycle from './zh_CN_Data/basic_information/goods_recycle';
import Region from './zh_CN_Data/basic_information/region';
import HeaderQuartersGoods from './zh_CN_Data/basic_information/headerquarters_goods';
import organizationMaterialIssue from './zh_CN_Data/basic_information/organization_material_issue';
import Brand from './zh_CN_Data/basic_information/brand';
import GoodsCorresponding from './zh_CN_Data/basic_information/goods_corresponding';
import transport from './zh_CN_Data/basic_information/transport';
import inventorySynchronization from './zh_CN_Data/basic_information/inventory_synchronization';
// 订单
import saleOrder from './zh_CN_Data/order/sale_order';
import saleInStorageOrder from './zh_CN_Data/order/sale_inStorage_order';
import saleDeliveryOrder from './zh_CN_Data/order/sale_delivery_order';
import saleInReturnOrder from './zh_CN_Data/order/sale_return_order';
import saleSwapOrder from './zh_CN_Data/order/sale_swap_order';
import purchaseOrder from './zh_CN_Data/order/purchase_order';
import requestOrder from './zh_CN_Data/order/request_order';
import purchaseInStorageOrder from './zh_CN_Data/order/purchase_inStorage_order';
import purchaseReturnOrder from './zh_CN_Data/order/purchase_return_order';
import purchaseSwapOrder from './zh_CN_Data/order/purchase_swap_order';
import coequalTransferOrder from './zh_CN_Data/order/coequal_transfer_order';
import gatheringOrder from './zh_CN_Data/order/gathering_order';
import paymentOrder from './zh_CN_Data/order/payment_order';
import OtherInStorageOrder from './zh_CN_Data/order/other_inStorage_order';
import OtherRetrunOrder from './zh_CN_Data/order/other_return_order';
import checkOrder from './zh_CN_Data/order/check_order';
import breakageOrder from './zh_CN_Data/order/breakage_order';
import inventoryStatistics from './zh_CN_Data/order/inventory_statistics';
import currentReconciliation from './zh_CN_Data/order/current_reconciliation';
import entrustDeliveryOrder from './zh_CN_Data/order/entrust_delivery_order';
import entrustConsignmentReturnOrder from './zh_CN_Data/order/entrust_consignment_return_order';
import commissionedConsignmentReceivingOrder from './zh_CN_Data/order/commissioned_consignment_receiving_order';
import commissionedConsignmentReturnOrder from './zh_CN_Data/order/commissioned_consignment_return_order';
// 系统管理
import operateLog from './zh_CN_Data/system_manage/log';
import Role from './zh_CN_Data/system_manage/role';
import Operator from './zh_CN_Data/system_manage/operator';

const zh_CN = {
    // 登陆页面
    login_title: "登录",
    login_btn_title: "登录",
    application_name: '企智盈-盈中台',
    input_username_placeholder: "账号",
    input_password_placeholder: "密码",
    no_userName_hint: '请输入账号',
    no_password_hint: '请输入密码',
    common: {
        add: '新 增',
        edit: '编 辑',
        clear_condition: '清空条件',
        search: '查 询',
        confirm: '确 定',
        cancel: '取 消',
        set_table_show: 'table显示设置',
        page_setting: '页面设置',
        back: '返回',
        clear_data: '清空数据',
        save: '保存',
        save_or_add: '保存再新增',
        export_in: '导入',
        export_out: '导出',
        download_template: '下载模板',
        newAdd_reference_title: '参照信息',
        newAdd_reference_explain: '说明：参照信息，只是再新增时用于数据参照、无其它业务！',
        issue_order: '下发订单',
        order_detail: '订单明细',
        first_add: '一级新增',
        clear_select: '清空选择',
        operate_success: '操作成功！',
        delete_hint: '确定要删除该数据吗？',
        please_input: '请输入',
        ok_text: '是',
        cancel_text: '否',
        back_up: '返回上一层',
        clear_catch: '清除缓存',
        hint: '提示',
        clear_catch_error: '清除缓存失败!',
        clear_catch_success: '清除缓存成功! 请关闭当前页面,并重新进入',
        menu_search_placeholder: '菜单搜索',
        authority_hint: '请选择权限code',
        change_Use: '修改数据状态，为启用',
        change_forbidden: '修改数据状态，为禁用',
        init_password: '确认要初始该用户密码吗？',
    },
    department: Department, // 部门
    staff: Staff, // 部门
    warehouse: Warehouse, // 仓库
    customer: Customer, // 客户
    goods_unit: GoodsUnit, // 商品单价
    organization: Organization, // 组织信息
    organization_group: OrganizationGroup, // 组织组档案
    account: Account, // 组织组档案
    organization_framework: OrganizationFramework, // 组织架构档案
    office: Office, // 办事处档案
    goods_classes: GoodsClasses, // 商品分类
    goods_largeClasses: GoodsLargeClasses, // 商品大类档案
    goods_smallClasses: GoodsSmallClasses, // 商品小类档案
    region: Region, // 大区档案
    headerquarters_goods: HeaderQuartersGoods, // 总部商品信息
    organization_material_issue: organizationMaterialIssue, // 组织物料下发关系
    sale_order: saleOrder, // 销售订单
    sale_InStorage_order: saleInStorageOrder, // 销售入库单
    sale_delivery_order: saleDeliveryOrder, // 销售出库单
    sale_return_order: saleInReturnOrder, // 销售退货单
    sale_swap_order: saleSwapOrder, // 销售换货单
    purchase_order: purchaseOrder, // 采购定单
    request_order: requestOrder, // 请购定单
    purchase_inStorage_order: purchaseInStorageOrder, // 采购入库单
    purchase_return_order: purchaseReturnOrder, // 采购出库单
    purchase_swap_order: purchaseSwapOrder, // 采购换货单
    coequal_transfer_order: coequalTransferOrder, // 同价调拨单
    gathering_order: gatheringOrder, // 收款单
    payment_order: paymentOrder, // 付款单
    other_inStorage_order: OtherInStorageOrder, // 其它入库单
    other_return_order: OtherRetrunOrder, // 其它出库单
    check_order: checkOrder, // 盘点单
    breakage_order: breakageOrder, // 报损报溢单
    inventory_statistics: inventoryStatistics, // 库存统计
    current_reconciliation: currentReconciliation, // 往来统计
    entrust_delivery_order: entrustDeliveryOrder, // 委托发货单
    entrust_consignment_return_order: entrustConsignmentReturnOrder, // 委托代销发货单
    commissioned_consignment_receiving_order: commissionedConsignmentReceivingOrder, // 委托代销收货单
    commissioned_consignment_return_order: commissionedConsignmentReturnOrder, // 委托代销收货单
    operate_log: operateLog, // 操作日志
    role: Role, // 角色管理
    operator: Operator, // 
    brand: Brand, // 品牌信息
    goods_corresponding: GoodsCorresponding, // 商品关系对应
    goods_recycle: GoodsRecycle, // 商品回收
    transport: transport, // 送达信息
    inventory_synchronization: inventorySynchronization, // 库存同步
}

export default zh_CN ;
