// 采购退货单

const purchaseReturnOrder = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 100, width: 100, pinned: 'left', hide: false, headerCheckboxSelection: true, checkboxSelection: true, cellRenderer: "filter_rowNumber"},
        { headerName: '单号', field: 'billNumber', hide: false, minWidth: 200},
        { headerName: '单据日期', field: 'billDate', hide: false, minWidth: 180},
        { headerName: '组织', field: 'organizationName', hide: false},
        { headerName: '账套', field: 'accsName', hide: false},
        { headerName: '往来单位', field: 'customerName', hide: false},
        { headerName: '职员', field: 'staffName', hide: false},
        { headerName: '仓库', field: 'warehouseName', hide: false},
        { headerName: '部门', field: 'departmentName', hide: false},
        { headerName: '制单人', field: 'inetypename', hide: false},
        { headerName: '优惠金额', field: 'discountAmount', hide: false},
        { headerName: '收付款账户', field: 'amountAccount', hide: false},
        { headerName: '收付款金额', field: 'amount', hide: false},
        { headerName: '送达方地址', field: 'deliveryPartAddress', hide: false, minWidth: 200},
        { headerName: '单据状态', field: 'billReturnState', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'bill_status_arr2'}},
        { headerName: '单据摘要', field: 'comment', hide: false, minWidth: 200},
        { headerName: '备注', field: 'remarks', hide: false, minWidth: 200},
        { headerName: '操作', field: 'operate', pinned: 'right' , minWidth: 0, width: 80 , cellRenderer: 'operate_element', hide: false, lockPosition: true},
    ],
    DetailColumnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true}, // 
        { headerName: '总部商品名称', field: 'itemHeadName', hide: false},
        { headerName: '商品名称', field: 'materielName', hide: false},
        { headerName: '品级', field: 'grade', hide: false},
        { headerName: '年限', field: 'years', hide: false},
        { headerName: '重量', field: 'weight', hide: false},
        { headerName: '采购单位', field: 'materielOrderSaleName', hide: false},
        { headerName: '采购数量', field: 'materielOrderSaleQuantity', hide: false},
        { headerName: '税率', field: 'taxRate', hide: false},
        { headerName: '折扣率', field: 'discount', hide: false},
        { headerName: '含税单价', field: 'priceTaxIncluding', hide: false},
        { headerName: '价税合计', field: 'priceTaxTotal', hide: false},
        { headerName: '税额', field: 'tax', hide: false},
        { headerName: '状态', field: 'state', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'bill_status_arr2'}},
        { headerName: '确认数量', field: 'confirmQty', hide: false, cellRenderer: "filter_custom_cell", cellRenderParams: {cellColumnDefs: [
            { headerName: '行号', field: 'rowNumber', minWidth: 70, width: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true}, // 
            { headerName: '确认时间', field: 'gmtCreate', hide: false},
            { headerName: '确认数量', field: 'confirmQty', hide: false},
        ], cellTitle: '确认数量记录'}},
        { headerName: '批号', field: 'batchNumber', hide: false},
    ],
    searchList: [
        {
            title: '单据编号',
            value: '',
            key: 'billNumber',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            label_title: '账套',
            value: '',
            show_value: '',
            key: 'accsCode',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'single',
            component: 'AccountInput'
        },
        {
            label_title: '组织',
            value: '',
            show_value: '',
            key: 'organizationId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'OrganizationInput'
        },
        {
            label_title: '客户',
            value: '',
            show_value: '',
            key: 'customerId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'CustomerInput'
        },
        {
            label_title: '部门',
            value: '',
            show_value: '',
            key: 'departmentId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'DepartmentInput'
        },
        {
            label_title: '仓库',
            value: '',
            show_value: '',
            key: 'warehouseId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'WarehouseInput'
        },
        {
            label_title: '职员',
            value: '',
            show_value: '',
            key: 'staffId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'StaffInput'
        },
        {
            title: '创建查询时段',
            value: [],
            key: 'search_creatTime',
            label_width: '100px',
            component: 'Stime'
        },
        {
            title: '更新查询时段',
            value: [],
            key: 'search_updateTime',
            label_width: '100px',
            component: 'Stime'
        },
    ],
};

export default purchaseReturnOrder;
