// 总部商品信息
import { staring_use, isOrno } from '../../../api/common.js';
const headerquarters_goods = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true},
        { headerName: '商品编号', field: 'code', hide: false},
        { headerName: '商品名称', field: 'name', hide: false,minWidth: 250},
        { headerName: '简名', field: 'shortName', hide: false},
        { headerName: '规格', field: 'standard', hide: false},
        // { headerName: '型号', field: 'type', hide: false},
        { headerName: '寸别', field: 'measure', hide: false},
        { headerName: '物料组', field: 'materialGroup', hide: false},
        { headerName: '外壳', field: 'shell', hide: false},
        { headerName: '花纹', field: 'flowerGrain', hide: false},
        { headerName: '重量', field: 'weight', hide: false},
        // { headerName: '品牌系列名称', field: 'brandSeriesName', hide: false},
        { headerName: '品牌名称', field: 'brandName', hide: false},
        { headerName: '负荷指数', field: 'loadIndex', hide: false},
        { headerName: '速度级别', field: 'speedLevel', hide: false},
        { headerName: '区域', field: 'area', hide: false},
        { headerName: '销售税率', field: 'saleTax', hide: false},
        { headerName: '销售单位', field: 'saleUnit', hide: false},
        { headerName: '采购单位', field: 'buyUnit', hide: false},
        { headerName: '库存单位', field: 'stockUnit', hide: false},
        { headerName: '采购税率', field: 'buyTax', hide: false},
        { headerName: '基本单位', field: 'baseUnit', hide: false},
        { headerName: '是否启用条码', field: 'isEntryCode', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '是否启用批号', field: 'isGoodsNumber', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '是否停止采购', field: 'isStopBuy', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '创建时间', field: 'gmtCreate', minWidth: 250, hide: false},
        { headerName: '修改时间', field: 'gmtModified', minWidth: 250, hide: false},
        { headerName: '商品类别', field: 'categoryName', hide: false},
        { headerName: '商品大类', field: 'largeName', hide: false},
        { headerName: '商品小类', field: 'smallName', hide: false},
        { headerName: '修改人', field: 'updatorName', hide: false},
        { headerName: '数据来源', field: 'source', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'data_origin'}},
        { headerName: '备注', field: 'comment', hide: false},
        { headerName: '状态', field: 'status', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'staring_use_arr'}},
        { headerName: '操作', field: 'operate', pinned: 'right' , cellRenderer: 'operate_element', hide: false, lockPosition: true},
    ],
    searchList: [
        {
            title: '商品名称',
            value: '',
            key: 'name',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            title: '商品编号',
            value: '',
            key: 'code',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            label_title: '商品类别',
            value: '',
            show_value: '',
            key: 'categoryKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'GoodsClassesInput'
        },
        {
            label_title: '商品大类',
            value: '',
            show_value: '',
            key: 'largeKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'GoodsLargeClassesInput'
        },
        {
            label_title: '商品小类',
            value: '',
            show_value: '',
            key: 'smallKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'GoodsSmallClassesInput'
        },
    ],
    modalList: [
        {
            title: '商品名称',
            value: '',
            key: 'name',
            is_required: true,
            component: 'StextInput'
        },
        {
            title: '商品编号',
            value: '',
            key: 'code',
            is_required: true,
            is_disabled: false,
            component: 'StextInput'
        },
        {
            title: '简名',
            value: '',
            key: 'shortName',
            component: 'StextInput'
        },
        {
            title: '规格',
            value: '',
            key: 'standard',
            component: 'StextInput'
        },
        // {
        //     title: '型号',
        //     value: '',
        //     key: 'type',
        //     component: 'StextInput'
        // },
        {
            title: '寸别',
            value: '',
            key: 'measure',
            component: 'StextInput'
        },
        {
            title: '外壳',
            value: '',
            key: 'shell',
            component: 'StextInput'
        },
        {
            title: '花纹',
            value: '',
            key: 'flowerGrain',
            component: 'StextInput'
        },
        {
            title: '重量',
            value: '',
            key: 'weight',
            component: 'StextInput'
        },
        {
            title: '速度级别',
            value: '',
            key: 'speedLevel',
            component: 'StextInput'
        },
        {
            title: '区域',
            value: '',
            key: 'area',
            component: 'StextInput'
        },
        {
            title: '负荷级别',
            value: '',
            key: 'loadIndex',
            component: 'StextInput'
        },
        {
            title: '物料组',
            value: '',
            key: 'materialGroup',
            component: 'StextInput'
        },
        {
            title: '品牌',
            value: '',
            show_value: '',
            key: 'brandName',
            label_width: '130px',
            component: 'StextInput'
        },
        {
            title: '销售税率',
            value: '',
            key: 'saleTax',
            component: 'SnumberInput'
        },
        {
            label_title: '销售单位',
            value: '',
            show_value: '',
            key: 'saleKey',
            show_key: 'name',
            data_key: 'name',
            is_required: true,
            is_refresh: false,
            label_width: '130px',
            component: 'GoodsUnitInput'
        },
        {
            label_title: '采购单位',
            value: '',
            show_value: '',
            key: 'buyKey',
            show_key: 'name',
            data_key: 'name',
            is_required: true,
            is_refresh: false,
            label_width: '130px',
            component: 'GoodsUnitInput'
        },
        {
            label_title: '库存单位',
            value: '',
            show_value: '',
            key: 'stockKey',
            show_key: 'name',
            data_key: 'name',
            is_required: true,
            is_refresh: false,
            label_width: '130px',
            component: 'GoodsUnitInput'
        },
        {
            title: '采购税率',
            value: '',
            key: 'buyTax',
            component: 'SnumberInput'
        },
        {
            label_title: '基本单位',
            value: '',
            show_value: '',
            key: 'baseKey',
            show_key: 'name',
            data_key: 'name',
            is_required: true,
            is_refresh: false,
            label_width: '130px',
            component: 'GoodsUnitInput'
        },
        {
            title: '是否启用条码',
            value: 0,
            key: 'isEntryCode',
            component: 'Sselect',
            select_list: isOrno
        },
        {
            title: '是否启用批号',
            value: 0,
            key: 'isGoodsNumber',
            component: 'Sselect',
            select_list: isOrno
        },
        {
            title: '是否停止采购',
            value: 0,
            key: 'isStopBuy',
            component: 'Sselect',
            select_list: isOrno
        },
        {
            label_title: '商品类别',
            value: '',
            show_value: '',
            key: 'categoryKey',
            show_key: 'name',
            data_key: 'ukey',
            is_refresh: false,
            label_width: '130px',
            component: 'GoodsClassesInput'
        },
        {
            label_title: '商品大类',
            value: '',
            show_value: '',
            key: 'largeKey',
            show_key: 'name',
            data_key: 'ukey',
            is_refresh: false,
            label_width: '130px',
            component: 'GoodsLargeClassesInput'
        },
        {
            label_title: '商品小类',
            value: '',
            show_value: '',
            key: 'smallKey',
            show_key: 'name',
            data_key: 'ukey',
            is_refresh: false,
            label_width: '130px',
            component: 'GoodsSmallClassesInput'
        },
        {
            title: '状态',
            value: 1,
            key: 'status',
            component: 'Sselect',
            is_required: true,
            select_list: staring_use
        },
        {
            title: '备注',
            value: '',
            key: 'comment',
            width: '440px',
            component: 'StextAreaInput'
        },
    ],
    unit_columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 80, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber"},
        // { headerName: '单位', field: 'unitKey', minWidth: 80, hide: false, cellRenderer: "filter_unit", cellEditor: 'table_select_input', cellEditorParams:{select_list: []}, editable: true},
        { headerName: '单位', field: 'unitKey', minWidth: 80, hide: false, cellEditor: 'table_select_input', cellEditorParams:{select_list: []}, editable: true},
        { headerName: '换算率', field: 'rate', minWidth: 80, hide: false, editable: true},
        { headerName: '采购价格', field: 'buyPrice', minWidth: 80, hide: false, cellEditor: 'number_input', editable: true},
        { headerName: '销售价格', field: 'salePrice', minWidth: 80, hide: false, cellEditor: 'number_input', editable: true},
        { headerName: '是否默认单位', field: 'isDefaultUnit', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}, cellEditor: 'table_select_input', cellEditorParams:{select_list: isOrno}, editable: true},
        { headerName: '状态', field: 'isUse', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'staring_use_arr'}, cellEditor: 'table_select_input', cellEditorParams:{select_list: staring_use}, editable: true},
        { headerName: '操作', field: '', pinned: 'right' , cellRenderer: 'operate_element', hide: false, minWidth: 80},
    ],
    goods_allUnit_title: '商品所有单位'
};

export default headerquarters_goods;