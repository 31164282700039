import { createStore, applyMiddleware } from 'redux';
import Logger from 'redux-logger';
import reducer from './reducer';
import thunk from 'redux-thunk';

let store;

const devStore = createStore(
    reducer,
    applyMiddleware(Logger, thunk)
);

const prodStore = createStore(
    reducer,
    applyMiddleware(thunk)
);

if (process.env.NODE_ENV === "production") {
    store = prodStore;
} else {
    store = devStore;
}

export default store;
