// 商品回收
import { isOrno } from '../../../api/common.js';
const goods_priceGroup = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true},
        { headerName: '商品编号', field: 'code', hide: false, minWidth: 150},
        { headerName: '商品名称', field: 'name', hide: false, minWidth: 250},
        { headerName: '商品简名', field: 'shortName', hide: false},
        { headerName: '规格', field: 'standard', hide: false},
        { headerName: '寸别', field: 'measure', hide: false},
        // { headerName: '型号', field: 'type', hide: false},
        { headerName: '物料组', field: 'materialGroup', hide: false},
        { headerName: '账套编号', field: 'accsCode', hide: false, minWidth: 150},
        { headerName: '账套名称', field: 'accsName', hide: false, minWidth: 150},
        { headerName: '外壳', field: 'shell', hide: false},
        { headerName: '花纹', field: 'flowerGrain', hide: false},
        { headerName: '重量', field: 'weight', hide: false},
        { headerName: '品牌名称', field: 'brandName', hide: false},
        { headerName: '速度级别', field: 'speedLevel', hide: false},
        { headerName: '负荷指数', field: 'loadIndex', hide: false},
        { headerName: '区域', field: 'area', hide: false},
        { headerName: '备注', field: 'comment', hide: false, minWidth:200},
        { headerName: '组织编号', field: 'orgCode', hide: false},
        { headerName: '组织名称', field: 'orgName', hide: false},
        { headerName: '商品类别', field: 'categoryName', hide: false},
        { headerName: '商品大类', field: 'itemLargeName', hide: false},
        { headerName: '商品小类', field: 'itemSmallName', hide: false},
        { headerName: '销售单位', field: 'saleUnit', hide: false},
        { headerName: '采购单位', field: 'buyUnit', hide: false},
        { headerName: '库存单位', field: 'stockUnit', hide: false},
        { headerName: '基本单位', field: 'baseUnit', hide: false},
        { headerName: '采购税率', field: 'buyTax', hide: false},
        { headerName: '销售税率', field: 'saleTax', hide: false},
        { headerName: '创建时间', field: 'gmtCreate', minWidth: 250, hide: false},
        { headerName: '修改时间', field: 'gmtModified', minWidth: 250, hide: false},
        { headerName: '是否总部商品', field: 'isHead', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '是否停止采购', field: 'isStopBuy', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '是否启用条码', field: 'isEntryCode', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '是否启用批号', field: 'isGoodsNumber', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '操作', field: 'operate', pinned: 'right' , cellRenderer: 'operate_element', hide: false, lockPosition: true},
    ],
    searchList: [
        {
            title: '商品名称',
            value: '',
            key: 'name',
            label_width: '80px',
            component: 'StextInput'
        },
        {
            title: '商品编号',
            value: '',
            key: 'code',
            label_width: '80px',
            component: 'StextInput'
        },
        {
            label_title: '账套',
            value: '',
            show_value: '',
            key: 'accsCodes',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '80px',
            selectType: 'multiple',
            component: 'AccountInput'
        },
    ],
    modalList: [
        {
            title: '换算率',
            value: '',
            key: 'rate',
            
            component: 'StextInput'
        },
        {
            title: '销售价格',
            value: '',
            key: 'salePrice',
            
            component: 'StextInput'
        },
        {
            title: '采购价格',
            value: '',
            key: 'buyPrice',
            
            component: 'StextInput'
        },
        {
            title: '预设进价1',
            value: '',
            key: 'defaultPurchasePrice1',
            
            component: 'StextInput'
        },
        {
            title: '预设进价2',
            value: '',
            key: 'defaultPurchasePrice2',
            
            component: 'StextInput'
        },
        {
            title: '预设进价3',
            value: '',
            key: 'defaultPurchasePrice3',
            
            component: 'StextInput'
        },
        {
            title: '预设进价4',
            value: '',
            key: 'defaultPurchasePrice4',
            
            component: 'StextInput'
        },
        {
            title: '预设进价5',
            value: '',
            key: 'defaultPurchasePrice5',
            
            component: 'StextInput'
        },
        {
            title: '最高进价',
            value: '',
            key: 'maximumPurchasePrice',
            
            component: 'StextInput'
        },
        {
            title: '预设售价1',
            value: '',
            key: 'defaultSellingPrice1',
            
            component: 'StextInput'
        },
        {
            title: '预设售价2',
            value: '',
            key: 'defaultSellingPrice2',
            
            component: 'StextInput'
        },
        {
            title: '预设售价3',
            value: '',
            key: 'defaultSellingPrice3',
            
            component: 'StextInput'
        },
        {
            title: '预设售价4',
            value: '',
            key: 'defaultSellingPrice4',
            
            component: 'StextInput'
        },
        {
            title: '预设售价5',
            value: '',
            key: 'defaultSellingPrice5',
            
            component: 'StextInput'
        },
        {
            title: '预设售价6',
            value: '',
            key: 'defaultSellingPrice6',
            
            component: 'StextInput'
        },
        {
            title: '预设售价7',
            value: '',
            key: 'defaultSellingPrice7',
            
            component: 'StextInput'
        },
        {
            title: '预设售价8',
            value: '',
            key: 'defaultSellingPrice8',
            
            component: 'StextInput'
        },
        {
            title: '预设售价9',
            value: '',
            key: 'defaultSellingPrice9',
            
            component: 'StextInput'
        },
        {
            title: '预设售价10',
            value: '',
            key: 'defaultSellingPrice10',
            
            component: 'StextInput'
        },
        {
            title: '最高售价',
            value: '',
            key: 'highestSellingPrice',
            
            component: 'StextInput'
        },
        {
            title: '最低售价',
            value: '',
            key: 'minimumSellingPrice',
            
            component: 'StextInput'
        },
        {
            title: '是否默认单位',
            value: '',
            key: 'isDefaultUnit',
            
            component: 'Sselect',
            select_list: isOrno
        },
    ],
};

export default goods_priceGroup;