// 组织物料下发关系
import { staring_use, isOrno } from '../../../api/common.js';
const organizationMaterialIssue = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true}, // 
        { headerName: '组织编号', field: 'orgCode', hide: false},
        { headerName: '组织名称', field: 'orgName', hide: false, minWidth: 200},
        { headerName: '账套编号', field: 'accsCode', hide: false},
        { headerName: '账套名称', field: 'accsName', hide: false, minWidth: 200},
        { headerName: '总部商品编号', field: 'itemCode', hide: false},
        { headerName: '总部商品名称', field: 'itemName', hide: false, minWidth: 200},
        { headerName: '创建时间', field: 'gmtCreate', minWidth: 250, hide: false},
        { headerName: '修改时间', field: 'gmtModified', minWidth: 250, hide: false},
        { headerName: '修改人', field: 'updatorName', hide: false},
        { headerName: '数据来源', field: 'source', hide: false, cellRenderer: "filter_source"},
        { headerName: '状态', field: 'status', hide: false,cellRenderer: "filter_useStatu"},
        { headerName: '是否下发', field: 'isNeedSend', hide: false,cellRenderer: "filter_issue"},
    ],
    searchList: [
        {
            label_title: '账套',
            value: '',
            show_value: '',
            key: 'accsCode',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '80px',
            selectType: 'multiple',
            component: 'AccountInput'
        },
        // {
        //     label_title: '组织',
        //     value: '',
        //     show_value: '',
        //     key: 'orgCode',
        //     show_key: 'name',
        //     data_key: 'ukey',
        //     is_auto: false,
        //     isRow_selection: true,
        //     label_width: '100px',
        //     selectType: 'multiple',
        //     component: 'OrganizationInput'
        // },
        {
            label_title: '总部商品',
            value: '',
            show_value: '',
            key: 'itemCode',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '80px',
            selectType: 'multiple',
            component: 'HearderQuartersGoods'
        },
    ],
    modalList: [
        {
            label_title: '账套',
            value: '',
            show_value: '',
            key: 'accsCode',
            show_key: 'name',
            data_key: 'ukey',
            is_required: true,
            is_refresh: false,
            label_width: '100px',
            component: 'AccountInput'
        },
        {
            label_title: '组织',
            value: '',
            show_value: '',
            key: 'orgKey',
            show_key: 'name',
            data_key: 'ukey',
            is_required: false,
            is_refresh: false,
            is_show: false,
            label_width: '100px',
            component: 'OrganizationInput'
        },
        {
            label_title: '总部商品',
            value: '',
            show_value: '',
            key: 'itemCodes',
            show_key: 'name',
            data_key: 'ukey',
            is_required: true,
            is_refresh: false,
            label_width: '100px',
            selectType: 'multiple',
            component: 'HearderQuartersGoods'
        },
        {
            title: '状态',
            value: 1,
            key: 'status',
            component: 'Sselect',
            select_list: staring_use
        },
        {
            title: '是否下发',
            value: 1,
            key: 'isNeedSend',
            component: 'Sselect',
            select_list: isOrno
        },
    ],
};

export default organizationMaterialIssue;
