
import React from 'react';
import { Provider } from 'react-redux';
import { GlobalStyle } from './style/init.style';
import CommonStyle from './assets/common.style';
import store from './reducer/store';
import routes from './router/index';
import { renderRoutes } from 'react-router-config';
import { HashRouter } from 'react-router-dom';
import Intl from './components/intl';
import './assets/iconfont/iconfont.css';
import './common.css';
import './App.less';

function App() {
  store.subscribe(()=>{
    let get_store = store.getState();

    let save_store = {};
    let arr = ['token', 'menu','is_develop'];
    for(let key in get_store){
      if(arr.includes(key)){

        save_store[key] = get_store[key];
      };
    };
    sessionStorage.store = JSON.stringify(save_store);
    
  })
  
  return (
    <Provider store={store}>
      <HashRouter>
        <GlobalStyle></GlobalStyle>
          <Intl>

            <CommonStyle>

            {renderRoutes(routes)}
            </CommonStyle>
          </Intl>
      </HashRouter>
    </Provider>
  );
}

export default App;
