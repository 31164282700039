// 商品关系对应
const goods_corresponding = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true},
        { headerName: '组织编号', field: 'orgCode', hide: false},
        { headerName: '组织名称', field: 'orgName', hide: false, minWidth: 200},
        { headerName: '账套编号', field: 'accsCode', hide: false},
        { headerName: '账套名称', field: 'accsName', hide: false, minWidth: 200},
        { headerName: '总部商品编号', field: 'itemCode', hide: false},
        { headerName: '总部商品名称', field: 'itemName', hide: false, minWidth: 200},
        { headerName: 'erp商品编号', field: 'erpCode', hide: false},
        { headerName: 'erp商品名称', field: 'erpName', hide: false},
        { headerName: '创建时间', field: 'gmtCreate', minWidth: 250, hide: false},
        { headerName: '修改时间', field: 'gmtModified', minWidth: 250, hide: false},
        { headerName: '修改人', field: 'updatorName', hide: false},
        { headerName: '数据来源', field: 'source', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'data_origin'}},
        { headerName: '是否对照商品', field: 'isHeadContrast', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
    ],
    searchList: [
        {
            title: '总部商品名称',
            value: '',
            key: 'name',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            title: '总部商品编号',
            value: '',
            key: 'code',
            label_width: '100px',
            component: 'StextInput'
        },
        // {
        //     title: '创建查询时段',
        //     value: [],
        //     key: 'search_creatTime',
        //     label_width: '100px',
        //     component: 'Stime'
        // },
        // {
        //     title: '更新查询时段',
        //     value: [],
        //     key: 'search_updateTime',
        //     label_width: '100px',
        //     component: 'Stime'
        // },
        // {
        //     title: '状态',
        //     value: '',
        //     key: 'status',
        //     label_width: '100px',
        //     component: 'Sselect',
        //     select_list_key: 'staring_use'
        // },
    ],
    modalList: [],
};

export default goods_corresponding;