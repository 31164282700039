import React from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Spin } from 'antd';

function Layout(props) {
    //props
    let { route } = props;

    //redux
    const is_loading = useSelector(state => state.is_loading)
    return (
        <Spin tip="Loading..." spinning={is_loading}><div>{renderRoutes(route.routes)}</div></Spin>
    )
}
export default Layout;
