
import PouchDB  from 'pouchdb';
import { isOrno_arr, staring_use_arr, order_types_arr, data_origin, customerType_arr, bill_status_arr, order_type_arr, marketing_type_arr, order_classes_arr, bill_status_arr2, settlement_status_arr, order_source_arr } from './common';

// 防抖函数
const debounce = (func, delay = 500) => {
    let timer;
    return function(...args) {
        if(timer){
            clearTimeout(timer);
        };

        timer = setTimeout(() => {
            func.apply(this, args);
            clearTimeout(timer);
        }, delay);
    };
};

// 节流
const throttle = (func, delay = 500) => {
    let canRun = true;
    return function () {
        if(!canRun) return;

        canRun = false;

        setTimeout(() => {
            func.apply(this, arguments);

            canRun = true;
        }, delay);
    }
}

// 标准化时间
const formart_date = ({time = '', space = '-'} = {}) => {
    let new_date = time ? new Date(time) : new Date();

    let year = new_date.getFullYear();
    let month = new_date.getMonth() + 1;
    let day = new_date.getDate();

    return `${year}${space}${month}${space}${day}`
};

// 新增验证
const add_verify = (list) => {
    if(!list || list.length === 0) {
        return
    };

    let hintTitle = '';
    for(let i = 0; i < list.length; i++){
        if( 'is_required' in list[i] && list[i].is_required){
            if(list[i].value === null || list[i].value.length === 0){
                hintTitle = list[i].title || list[i].label_title;
                break;
            };
        };
    };

    if(hintTitle){
        return {
            verfiy_statu: false,
            message_title: `${hintTitle}`
        }
    };

    return {
        verfiy_statu: true
    }
};

 // 过滤查询值
const filter_search_data = (list) => {
    if(!list || list.length === 0) {
        return
    };

    let param = list.reduce(function(init, item){
        if(item.value !== ''){

            if(item.key === 'search_creatTime') {
                if(item.value.length){

                    let arr = ['startCreateTime', 'endCreateTime'];
                    arr.forEach(function(citem, cindex){
                        let time = formart_date({time: item.value[cindex]});
                        init[citem] = cindex === 0 ? `${time} 00:00:00` : `${time} 23:59:59`;
                    });
                }
            } else if(item.key === 'search_updateTime') {
                if(item.value.length){

                    let arr = ['startUpdateTime', 'endUpdateTime'];
                    arr.forEach(function(citem, cindex){
                        let time = formart_date({time: item.value[cindex]});
                        init[citem] = cindex === 0 ? `${time} 00:00:00` : `${time} 23:59:59`;
                    });
                }
            } else {

                init[item.key] = item.value;
            };
        };

        return init;
    }, {});

    return {'param': param};
};

const filter_search_data1 = ({list = [], key1 = 'search_creatTime' , format1 = ['startCreateTime', 'endCreateTime'], key2 = 'search_updateTime',format2 = ['startUpdateTime', 'endUpdateTime'] } = {}) => {
    if(!list || list.length === 0) {
        return
    };

    let param = list.reduce(function(init, item){
        if(item.value !== ''){

            if(item.key === key1) {
                if(item.value.length){

                    format1.forEach(function(citem, cindex){
                        let time = formart_date({time: item.value[cindex]});
                        init[citem] = cindex === 0 ? `${time} 00:00:00` : `${time} 23:59:59`;
                    });
                }
            } else if(item.key === key2) {
                if(item.value.length){

                    format2.forEach(function(citem, cindex){
                        let time = formart_date({time: item.value[cindex]});
                        init[citem] = cindex === 0 ? `${time} 00:00:00` : `${time} 23:59:59`;
                    });
                }
            } else {

                init[item.key] = item.value;
            };
        };

        return init;
    }, {});

    return {'param': param};
}

// 创建db
const create_db =  (useCallback) => {
    let db_name = `${sessionStorage.Number}_db`;
    const db = new PouchDB(db_name);

    db.info(function(err, info){
        if(err){
            console.error(err, '客户端DB异常报错!!!!')
        } else {
            useCallback(db)
        }
    });
    
}

// 过滤cell显示值
function filter_cell_chow(data){
    let cell_content = '';
        switch(data.filter_key){
            case 'isOrno_arr':
                cell_content = isOrno_arr[data.value];
                break;
            case 'staring_use_arr':
                cell_content = staring_use_arr[data.value];
                break;
            case 'order_types_arr':
                cell_content = order_types_arr[data.value];
                break;
            case 'data_origin':
                cell_content = data_origin[data.value];
                break;
            case 'customerType_arr':
                cell_content = customerType_arr[data.value];
                break;
            case 'bill_status_arr':
                cell_content = bill_status_arr[data.value];
                break;
            case 'order_type_arr':
                cell_content = order_type_arr[data.value];
                break;
            case 'marketing_type_arr':
                cell_content = marketing_type_arr[data.value];
                break;
            case 'order_classes_arr':
                cell_content = order_classes_arr[data.value];
                break;
            case 'bill_status_arr2':
                cell_content = bill_status_arr2[data.value];
                break;
            case 'settlement_status_arr':
                cell_content = settlement_status_arr[data.value];
                break;
            case 'order_source_arr':
                cell_content = order_source_arr[data.value];
                break;
            default:
                break;
        };
    return cell_content
}
export {
    debounce,
    throttle,
    formart_date,
    add_verify,
    filter_search_data,
    filter_search_data1,
    create_db,
    filter_cell_chow
};
