// 运送信息
const transport = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 70, width:70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true},
        { headerName: '送达方编号', field: 'code', hide: false},
        { headerName: '送达方名称', field: 'name', hide: false, minWidth: 200},
        { headerName: '账套编号', field: 'accsCode', hide: false},
        { headerName: '账套名称', field: 'accsName', hide: false, minWidth: 200},
        { headerName: '组织编号', field: 'orgCode', hide: false},
        { headerName: '组织名称', field: 'orgName', hide: false, minWidth: 200},
        { headerName: '省', field: 'provName', hide: false},
        { headerName: '市', field: 'cityName', hide: false},
        { headerName: '区/县', field: 'counName', hide: false},
        { headerName: '详细地址', field: 'address', hide: false},
        { headerName: '联系人', field: 'address', hide: false},
        { headerName: '联系电话', field: 'address', hide: false},
        { headerName: '创建时间', field: 'gmtCreate', minWidth: 250, hide: false},
        { headerName: '修改时间', field: 'gmtModified', minWidth: 250, hide: false},
        { headerName: '数据来源', field: 'source', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'data_origin'}},
        { headerName: '状态', field: 'status', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'staring_use_arr'}},
        { headerName: '是否下发', field: 'isNeedSend', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '备注', field: 'comment', hide: false, minWidth: 250},
    ],
    searchList: [
        {
            title: '送达方名称',
            value: '',
            key: 'name',
            label_width: '90px',
            component: 'StextInput'
        },
        {
            title: '送达方编号',
            value: '',
            key: 'code',
            label_width: '90px',
            component: 'StextInput'
        },
        {
            label_title: '账套',
            value: '',
            show_value: '',
            key: 'accsCodes',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '90px',
            selectType: 'multiple',
            component: 'AccountInput'
        },
    ],
    modalList: [],
};

export default transport;
