// 仓库信息
// import { staring_use, isOrno } from '../../../api/common.js';
const warehouse = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 80, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true},
        { headerName: '仓库编号', field: 'code', hide: false},
        { headerName: '仓库名称', field: 'name', hide: false, minWidth: 200},
        { headerName: '账套编号', field: 'accsCode', hide: false},
        { headerName: '账套名称', field: 'accsName', hide: false, minWidth: 200},
        { headerName: '电话', field: 'tel', hide: false},
        { headerName: '联系人', field: 'linkman', hide: false},
        { headerName: '创建时间', field: 'gmtCreate', minWidth: 250, hide: false},
        { headerName: '修改时间', field: 'gmtModified', minWidth: 250, hide: false},
        { headerName: '组织', field: 'orgName', hide: false},
        { headerName: '省', field: 'provName', hide: false},
        { headerName: '市', field: 'cityName', hide: false},
        { headerName: '区', field: 'counName', hide: false},
        { headerName: '详细地址', field: 'address', hide: false},
        { headerName: '修改人', field: 'updatorName', hide: false},
        { headerName: '数据来源', field: 'source', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'data_origin'}},
        { headerName: '状态', field: 'status', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'staring_use_arr'}},
        { headerName: '备注', field: 'comment', hide: false, minWidth: 250},
    ],
    searchList: [
        {
            title: '仓库名称',
            value: '',
            key: 'name',
            label_width: '80px',
            component: 'StextInput'
        },
        {
            title: '仓库编号',
            value: '',
            key: 'code',
            label_width: '80px',
            component: 'StextInput'
        },
        {
            label_title: '账套',
            value: '',
            show_value: '',
            key: 'accsCodes',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '80px',
            selectType: 'multiple',
            component: 'AccountInput'
        },
    ],
    modalList: [],
};

export default warehouse;
