// 往来对账
import { bill_status } from '../../../api/common';

const currentReconciliation = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 90, pinned: 'left', hide: false, headerCheckboxSelection: true, checkboxSelection: true},
        { headerName: '组织编号', field: 'Stypecode', hide: false},
        { headerName: '组织名称', field: 'Stypename', hide: false},
        { headerName: '客户编号', field: 'Btypecode', hide: false},
        { headerName: '客户名称', field: 'Btypename', hide: false},
        { headerName: '账款', field: 'Accounts', hide: false},
        { headerName: '更新时间', field: 'Updatetime', hide: false},
    ],
    searchList: [
        {
            title: '单据编号',
            value: '',
            key: 'Usercode',
            component: 'StextInput'
        },
        {
            label_title: '经销商',
            value: '',
            show_value: '001',
            key: 'Stypekey',
            show_key: 'Username',
            is_auto: false,
            isRow_selection: true,
            selectType: 'multiple',
            component: 'DealerInput'
        },
        {
            title: '查询时段',
            value: ['2021-1-1', '2021-4-29'],
            key: 'search_time',
            component: 'Stime'
        },
        {
            title: '单据状态',
            value: 1,
            key: 'IfGoodsNumber',
            component: 'Sselect',
            select_list: bill_status
        },
    ],
};

export default currentReconciliation;