import React, { useEffect, useCallback, useState } from 'react';
import IndexWrapper from '../style/index_layout.style';
import white_logo from '../assets/image/qy_logo.png';
import UserHeader from '../assets/image/admin.png';
import { Menu, Select, Popover } from 'antd';
import { KeepaliveRouterSwitch ,addKeeperListener, useCacheDispatch } from 'react-keepalive-router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector, useDispatch } from 'react-redux';
import { createDB } from '../reducer/actionCreators';
import { changeToken, changeMenu, changeIsDevelop, changeCurrentMenu } from '../reducer/actionCreators';
import { useLocalObservable , observer } from 'mobx-react';
import { toJS } from 'mobx';
import { create_db, throttle } from '../api/utils';
import intl from 'react-intl-universal';
import 'swiper/swiper.min.css';

import { renderRoutes } from 'react-router-config';
const { SubMenu } = Menu;
const cacheDispatch = useCacheDispatch();
const { Option } = Select;

function IndexLayout(props) {
    // props
    let {routes} = props.route;

    // redux
    const dispatch = useDispatch();
    // const memu_list = useSelector(state => state.menu);
    const Token = useSelector(state => state.token);
    const DB = useSelector(state => state.db);

    // store
    const store = useLocalObservable(() => ({
        collapsed: true,
        change_collapsed: function(statu){
            store.collapsed = statu;
        },
        now_time: '',
        change_now_time: function(date){
            store.now_time = date;
        },
        tag_list: [],
        changeTag_list: function(list){
            
            if(list.length){
                let currentMenu = list.filter(item => item.active === true)[0];
                dispatch(changeCurrentMenu(currentMenu));
            } else {
                dispatch(changeCurrentMenu(null));
            }

            store.tag_list = list;
        },
        menu_search_val: '',
        changeMenu_searchVal: function(val){
            store.menu_search_val = val;
        },
        menu_option: [],
        setMenu_option: function(list){
            store.menu_option = list;
        },
        global_setting: [
            {
                title: '关闭菜单',
                value: 'cloneMenu'
            },
            {
                title: '修改密码',
                value: 'changePassword'
            },
        ],
        contextMenu_style: {},
        change_contextMenu_style: function(style){
            store.contextMenu_style = style;
        },
        contextMenu_show: false,
        change_contextMenu_show: function(statu){
            store.contextMenu_show = statu;
        },
        contextMenu_data: '',
        change_contextMenu_data: function(data){
            store.contextMenu_data = data;
        },
        createDB_statu: false,
        change_createDB_statu: function(statu){
            store.createDB_statu = statu;
        }
    }));

    // data
    const [memu_list] = useState([
        {
            title: '首页',
            name: '首页',
            icon: 'iconshouye',
            key: 'home',
            url: '/index/home',
            child: []
        },
        {
            title: '主数据',
            name: '主数据',
            icon: 'iconjichu',
            key: 'base',
            child: [
                {
                    title: '组织',
                    key: 'base1',
                    child: [
                        {
                            name: '总部组织档案',
                            key: 'base1-1',
                            url: '/index/OrganizationInformation'
                        },
                        {
                            name: '总部账套',
                            key: 'base1-4',
                            url: '/index/AccountInformation'
                        },
                    ]
                },
                {
                    title: '商品',
                    key: 'base2',
                    child: [
                        {
                            name: '组织物料下发关系',
                            key: 'base2-1',
                            url: '/index/OMIinformation'
                        },
                        {
                            name: '组织物料回收',
                            key: 'base2-2',
                            url: '/index/GoodsRecycleInformation'
                        },
                        {
                            name: '总部商品',
                            key: 'base2-3',
                            url: '/index/HeaderquarterGoods'
                        },
                        {
                            name: '总部商品单位',
                            key: 'base2-4',
                            url: '/index/GoodsUnit'
                        },
                        {
                            name: '总部商品类别',
                            key: 'base2-5',
                            url: '/index/GoodsClasses'
                        },
                        {
                            name: '总部商品大类档案',
                            key: 'base2-6',
                            url: '/index/GoodsLargeClasses'
                        },
                        {
                            name: '总部商品小类档案',
                            key: 'base2-7',
                            url: '/index/GoodsSmallClasses'
                        },
                        // {
                        //     name: '总部商品品牌档案',
                        //     key: 'base2-8',
                        //     url: '/index/BrandInformation'
                        // },
                        {
                            name: '总部商品对照',
                            key: 'base2-9',
                            url: '/index/GoodsCorrespondingInformation'
                        },
                    ]
                },
                {
                    title: '组织关系',
                    key: 'base3',
                    child: [
                        {
                            name: '部门信息',
                            key: 'base3-1',
                            url: '/index/DepartmentInformation'
                        },
                        {
                            name: '客户信息',
                            key: 'base3-2',
                            url: '/index/CustomerInformation'
                        },
                        {
                            name: '职员信息',
                            key: 'base3-3',
                            url: '/index/StaffInformation'
                        },
                        {
                            name: '仓库信息',
                            key: 'base3-4',
                            url: '/index/WarehouseInformation'
                        },
                        {
                            name: '送达方信息',
                            key: 'base3-5',
                            url: '/index/TransportInformation'
                        },
                        {
                            name: '库存同步',
                            key: 'base3-6',
                            url: '/index/InventorySynchronization'
                        },
                    ]
                },
            ]
        },
        {
            title: '业务中台',
            name: '业务中台',
            icon: 'iconreport-fill',
            key: 'bill',
            child: [
                {
                    title: '销售',
                    key: 'bill1',
                    child: [
                        {
                            name: '销售订单',
                            key: 'bill1-1',
                            url: '/index/SaleOrder'
                        },
                        {
                            name: '销售出库订单',
                            key: 'bill1-3',
                            url: '/index/SaleDeliveryOrder'
                        },
                        {
                            name: '销售退货单',
                            key: 'bill1-5',
                            url: '/index/SaleReturnOrder'
                        },
                    ]
                },
                {
                    title: '采购',
                    key: 'bill2',
                    child: [
                        {
                            name: '采购单',
                            key: 'bill2-1',
                            url: '/index/PurchaseOrder'
                        },
                        {
                            name: '采购入库单',
                            key: 'bill2-3',
                            url: '/index/PurchaseInStorageOrder'
                        },
                        {
                            name: '采购退货单',
                            key: 'bill2-4',
                            url: '/index/PurchaseRetrunOrder'
                        },
                    ]
                },
                {
                    name: '其它出入库单',
                    key: 'bill6',
                    url: '/index/OtherInStorageOrder' 
                },
                {
                    name: '盘点单',
                    key: 'bill10',
                    url: '/index/CheckOrder' 
                },
                {
                    name: '报损报溢单',
                    key: 'bill11',
                    url: '/index/BreakageOrder' 
                },
                {
                    name: '委托发货单',
                    key: 'EntrustDeliveryOrder',
                    url: '/index/EntrustDeliveryOrder' 
                },
                {
                    name: '委托代销退货单',
                    key: 'EntrustConsignmentReturnOrder',
                    url: '/index/EntrustConsignmentReturnOrder' 
                },
                {
                    name: '委托代销收货单',
                    key: 'commissionedConsignmentReceivingOrder',
                    url: '/index/commissionedConsignmentReceivingOrder' 
                },
                {
                    name: '委托代销退货单',
                    key: 'commissionedConsignmentReturnOrder',
                    url: '/index/commissionedConsignmentReturnOrder' 
                },
            ]
        },
        {
            title: '系统管理',
            name: '系统管理',
            icon: 'iconxitongguanli',
            key: 'system',
            child: [
                {
                    name: '角色管理',
                    key: 'system1',
                    url: '/index/RoleSetting' 
                },
                {
                    name: '操作员管理',
                    key: 'system2',
                    url: '/index/OperatorSetting'
                },
                {
                    name: '日志',
                    key: 'system3',
                    url: '/index/OperateLog'
                },
            ]
        },
    ]);

    // useCallBack
    const change_tag_list = useCallback((newData) => {
        // session缓存tag_list
        sessionStorage.tag_list = JSON.stringify(newData);
        store.changeTag_list(newData);
    }, [store]);

    // function
    
    const close_rightTagAll = () => {
        let { index, data } = toJS(store.contextMenu_data);
        if(index >= 0){
            let newPage
            let newList = toJS(store.tag_list).reduce(function(init, item, Dindex){
                item.active = false;

                if(Dindex <= index){
                    if(item.title === data.title){
                        item.active = true;
                        newPage = item;
                    };

                    init.push(item);
                };

                return init;
            }, []);

            if(data.active === false){
                newPage && props.history.push(newPage.url);
            };
            
            store.changeTag_list(newList);
        };

        close_contextMenu();
    };
    
    const close_leftTagAll = () => {
        let { index } = toJS(store.contextMenu_data);
        if(index > 0){
            let newList = toJS(store.tag_list).reduce(function(init, item, Dindex){
                if(Dindex >= index){
                    init.push(item);
                };

                return init;
            }, []);

            store.changeTag_list(newList);
        };

        close_contextMenu();
    };
    
    const close_tagAll = () => {
        sessionStorage.tag_list = '';
        store.changeTag_list([]);
        props.history.push('/index/home');

        cacheDispatch({type: 'reset'});

        close_contextMenu();
    };

    const close_contextMenu = () => {
        store.change_contextMenu_show(false);
    };


    const menuSearchSelect = (val, option) => {
        let filter_data = toJS(store.menu_option).reduce(function(init, item){
            if(item.key === val){
                init = item;
            };
            return init;
        }, '');

        MenuClick({item: {
            props: filter_data
        }})
    };
    const menuSearch = (val) => {
        store.changeMenu_searchVal(val);
        let filter_data = memu_list.reduce(function(init, item){
            if( 'child' in item && item.child.length){
                item.child.forEach(function(Citem){
                    if('child' in Citem && Citem.child.length){
                        Citem.child.forEach(function(Ccitem){
                            let title  = Ccitem.name ? Ccitem.name : Ccitem.title;
                            if(title && title.includes(val)){

                                let obj = {};
                                obj.title = Ccitem.name ? Ccitem.name : Ccitem.title;
                                obj.key = Ccitem.key;
                                obj.url = Ccitem.url;
    
                                init.push(obj);
                            }
                        });
                    } else {
                        let title  = Citem.name ? Citem.name : Citem.title;
                        if(title && title.includes(val)){

                            let obj = {};
                            obj.title = Citem.name ? Citem.name : Citem.title;
                            obj.key = Citem.key;
                            obj.url = Citem.url;

                            init.push(obj);
                        };
                    }
                });
            }
            return init;
        }, []);

        store.setMenu_option(filter_data);
    };
    const quite = () => {
        sessionStorage.clear();

        dispatch(changeToken(''));
        dispatch(changeMenu([]));
        dispatch(changeIsDevelop(false));
        
        props.history.push('/Login')
    };
    const change_collapsed = () => {
        store.change_collapsed(!store.collapsed);
    };
    const MenuItem = (item, index) => {
        if('child' in item && item.child.length) {
            return ChildMenuItem(item, index)
        } else {
            return <Menu.Item key={item.key} url={item.url.length ? item.url : ''} icon={<span className="anticon anticon-appstore"><i className={`iconfont ${item.icon}`}></i></span>}>{item.title}</Menu.Item>
        }
    };
    const filterIcon = (data) => {
        if(data.icon){

            return <span className="anticon anticon-appstore"><i className={`iconfont ${data.icon}`}></i></span>
        };
        return '';
    };

    const ChildMenuItem = (data, index) => {
        let menu_list = <SubMenu key={data.key} icon={filterIcon(data)} title={data.title}>
            {
                data.child.map((c_item, c_index)=> (
                    ('child' in c_item && c_item.child.length) ? ChildMenuItem(c_item, c_index) : <Menu.Item key={c_item.key} url={c_item.url ? c_item.url : ''} >{c_item.name ? c_item.name : c_item.title}</Menu.Item>
                ))
            }
        </SubMenu>;
        return menu_list;

    };

    const MenuClick = ({ item, key, keyPath, domEvent }) => {

        if(!item.props.url){
            return;
        };

        if(item.props.url.includes("http")){
            let url = `${item.props.url}?key=${Token}&value=${'admin'}`;
            window.open(url);
            return
        }
        
        if(item.props.url === '/index/home'){
            let newTage_list = toJS(store.tag_list).reduce(function(init, c_item){
                c_item.active = false;
    
                init.push(c_item)
                return init;
            }, []);

            change_tag_list(newTage_list);
            props.history.push('/index/home');
            return
        };

        let clone_tagList = [...toJS(store.tag_list)];
        let Tage_url = clone_tagList.reduce(function(init, c_item){
            if(item){
                c_item.active = false;
                if(c_item.url === item.props.url){
                    c_item.active = true;
                }
            };

            init.push(c_item.url)
            return init;
        }, []);
        
        if(!(Tage_url.includes(item.props.url))){
            let title = '';
            if('node' in item){
                title = item.node.innerText;
            } else {
                title = item.props.title ? item.props.title : item.props.name;
            }
            clone_tagList.push({
                title: title,
                url: item.props.url,
                active: true
            });

        };
        
        change_tag_list(clone_tagList);

        setTimeout(function(){
            item.props.url && props.history.push(item.props.url)

        }, 0);
    }

    const tag_close = (e, data, index) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        clear_single_catch(data.url);

        let newTage_list = toJS(store.tag_list).reduce(function(init, item, c_index){
            if(data.active){
                item.active = false;
            }

            if(c_index !== index){
                init.push(item);
            };
            return init;
        }, []);

        if(newTage_list.length === 0) {
            props.history.push('/index/home');
        } else {
            if(index >= 1){
                if(data.active === true){
                    newTage_list[index - 1].active = true;
                    props.history.push(newTage_list[index - 1].url);
                };
            };

            if(index === 0) {
                if(data.active === true){

                    newTage_list[index].active = true;
                    props.history.push(newTage_list[index].url);
                };
            };
        };

        change_tag_list(newTage_list);
    };

    const tag_click = (e, data) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        let newTag_list = toJS(store.tag_list).reduce(function(init, item){
            item.active = false;
            if(data.url === item.url){
                item.active = true;
            };

            init.push(item);
            return init;
        }, []);

        change_tag_list(newTag_list);

        data.url && props.history.push(data.url);
    };

    const tagContentMenu = (e, data, index) => {
        window.event.returnValue = false;
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        
        store.change_contextMenu_style({
            left: store.collapsed ? `${e.pageX - 40}px`: `${e.pageX - 120}px`,
            top: store.collapsed ? `${e.pageY - 50}px`: `${e.pageY - 50}px`
        });

        store.change_contextMenu_show(true);

        store.change_contextMenu_data({
            index: index,
            data: data
        });
    };

    const GlobalSet = (val) => {
        if(val === 'cloneMenu' || val === 'changePassword'){
            sessionStorage.tag_list = '';
            store.changeTag_list([]);

            if(val === 'changePassword'){
                props.history.push('/change_parssword')
            } else {
                props.history.push('/index/home')
            }
        };
    };

    const clear_single_catch = (val) => {
        
        cacheDispatch({type: 'reset', payload: `${val}`})
    };

    let OptionList = toJS(store.menu_option).map((d) => <Option key={d.key}>{d.title ? d.title : d.name}</Option>);

    const GlobalSettingContent = (
        <div className="setting_content">
            {
                toJS(store.global_setting).map((item) => (

                    <div className="setting_item" onClick={() => GlobalSet(item.value)} key={item.value}>{item.title}</div>
                ))
            }
        </div>
    );

    // useEffect
    useEffect(function(){
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDay();
        let now_time = `${year}~${month}~${day}`;
        store.change_now_time(now_time);

    }, [store]);

    useEffect(()=>{
        /* 增加缓存监听器 */
        addKeeperListener((history,cacheKey)=>{
            // if(history)console.log('当前激活状态缓存组件：'+ cacheKey )
        });
        
        if(sessionStorage.store){
            let { token, menu, is_develop} = JSON.parse(sessionStorage.store);
            token && !Token && dispatch(changeToken(token));
            if(memu_list.length === 0 && menu.length > 0){
                menu && dispatch(changeMenu(menu));
            }
            if(is_develop){
                dispatch(changeIsDevelop(is_develop));
            }
        } else {
            props.history.push('/')
        };

        if(sessionStorage.tag_list && store.tag_list.length === 0) {
            let store_tag_list = JSON.parse(sessionStorage.tag_list);
            if(store_tag_list.length){
                change_tag_list(store_tag_list);
            }
        };

        if(!DB && store.createDB_statu === false){
            store.change_createDB_statu(true);

            throttle(
                // 创建DB
                create_db(function(res){
                    
                    dispatch(createDB(res))
                })
            );
        }
        
    },[dispatch, props, store, memu_list, change_tag_list, DB, Token])

    return (
        <IndexWrapper>
            <div className="header">
                <div className="system_name">
                    <img src={white_logo} alt=""/>
                    赛轮中台系统</div>
                
                <div className="right_content">
                    <Select
                        showSearch
                        placeholder={`${intl.get('common.menu_search_placeholder')}`}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        className="_self_selet"
                        filterOption={false}
                        onSearch={menuSearch}
                        onChange={menuSearchSelect}
                        notFoundContent={null}
                    >
                        {OptionList}
                    </Select>
                    <div className="headerImage">
                        <img src={UserHeader} alt="" className="user_role_header"/>
                    </div>
                    <div className="user_meassge">{store.now_time}</div>
                    <div className="global_setting">
                        <Popover placement="bottomRight" title={"全局设置"} content={GlobalSettingContent} trigger="hover">
                            <i className="iconfont iconshezhi"></i>
                        </Popover>
                    </div>
                    <div className="quite" onClick={quite}>
                        <i className="iconfont icontuichu"></i>
                    </div>
                </div>
            </div>
            <div className="contentes">
                <div className="menu_wrapper">
                    <div onClick={change_collapsed} className="main_icon">
                        <i className={`iconfont ${store.collapsed ? 'iconicon_paging_right': 'iconicon_paging_left'}`}></i>
                    </div>
                    <Menu
                        defaultSelectedKeys={[1]}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        theme="dark"
                        inlineCollapsed={store.collapsed}
                        onClick={MenuClick}
                    >
                        {
                            memu_list.map((item, index) => (
                                MenuItem(item, index)
                            ))
                        }
                    </Menu>
                </div>
                <div className="right_content">
                    <div className="tag_wrapper">
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={'auto'}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => {/*swiper实例*/}}
                            >
                                {
                                    toJS(store.tag_list).map((item, index) => {
                                        return (
                                            <SwiperSlide key={`s${index}`}>
                                                <div className={`self_tag ${item.active ? 'active_tag' : ''}`} onClick={(e) => tag_click(e, item)} onContextMenu={(e) => tagContentMenu(e, item, index)}>{item.title}<i className="iconfont iconicon_guanbi" onClick={(e) => tag_close(e, item, index)}></i> </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            
                        </Swiper>
                        {
                            store.contextMenu_show ? <div className="contentMenu_content" style={toJS(store.contextMenu_style)}>
                                <div className="contentMenu_item" onClick={close_rightTagAll}>关闭右侧模块</div>
                                <div className="contentMenu_item" onClick={close_leftTagAll}>关闭左侧模块</div>
                                <div className="contentMenu_item" onClick={close_tagAll}>关闭所有</div>
                                <div className="contentMenu_item contentMenu_footer" onClick={close_contextMenu}>关闭弹窗</div>
                            </div> : ''
                        }
                        
                    </div>
                    <div className="_view_wrapper sroll_wrapper">
                        <KeepaliveRouterSwitch withoutRoute  >{ renderRoutes(routes) }</KeepaliveRouterSwitch> 
                    </div>
                </div>
            </div>
        </IndexWrapper>
    )
};

export default observer(IndexLayout);
