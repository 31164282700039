// 库存统计
// import { bill_status } from '../../../api/common';

const inventoryStatistics = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber"},
        { headerName: '组织编号', field: 'stypeCode', hide: false},
        { headerName: '组织名称', field: 'stypeName', hide: false},
        { headerName: '仓库编号', field: 'ktypeCode', hide: false},
        { headerName: '仓库名称', field: 'ktypeName', hide: false},
        { headerName: '商品编号', field: 'ptypeCode', hide: false},
        { headerName: '商品名称', field: 'ptypeName', hide: false},
        { headerName: '基本单位', field: 'unit', hide: false},
        { headerName: '实际账面数量', field: 'qty', hide: false},
        { headerName: '账面成本总金额', field: 'totalMoney', hide: false},
        // { headerName: '订单可用数量', field: 'Orderqty', hide: false},
        // { headerName: '草稿可用数量', field: 'Draftqty', hide: false},
        { headerName: '更新时间', field: 'gmtModified', hide: false},
    ],
    searchList: [
        {
            label_title: '组织',
            value: '',
            show_value: '',
            key: 'stypeKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'OrganizationInput'
        },
        {
            label_title: '仓库',
            value: '',
            show_value: '',
            key: 'ktypeKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'WarehouseInput'
        },
        {
            label_title: '商品',
            value: '',
            show_value: '',
            key: 'ptypeKey',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'GoodsInput'
        },
    ],
};

export default inventoryStatistics;