// 商品小类档案
import { staring_use, isOrno } from '../../../api/common.js';
const goods_smallClasses = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 70, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true},
        { headerName: '商品小类编号', field: 'code', hide: false},
        { headerName: '商品小类名称', field: 'name', hide: false},
        { headerName: '创建时间', field: 'gmtCreate', minWidth: 250, hide: false},
        { headerName: '修改时间', field: 'gmtModified', minWidth: 250, hide: false},
        { headerName: '修改人', field: 'updatorName', hide: false},
        { headerName: '数据来源', field: 'source', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'data_origin'}},
        { headerName: '状态', field: 'status', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'staring_use_arr'}},
        { headerName: '是否下发', field: 'isNeedSend', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '操作', field: 'operate', pinned: 'right' , cellRenderer: 'operate_element', hide: false, lockPosition: true},
    ],
    searchList: [
        {
            title: '商品小类名称',
            value: '',
            key: 'name',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            title: '商品小类编号',
            value: '',
            key: 'code',
            label_width: '100px',
            component: 'StextInput'
        },
    ],
    modalList: [
        {
            title: '商品小类名称',
            value: '',
            key: 'name',
            is_required: true,
            component: 'StextInput'
        },
        {
            title: '商品小类编号',
            value: '',
            key: 'code',
            is_required: true,
            is_disabled: false,
            component: 'StextInput'
        },
        {
            title: '状态',
            value: 1,
            key: 'status',
            component: 'Sselect',
            is_required: true,
            select_list: staring_use
        },
        {
            title: '是否下发',
            value: 1,
            key: 'isNeedSend',
            component: 'Sselect',
            is_required: true,
            select_list: isOrno
        },
    ],
};

export default goods_smallClasses;