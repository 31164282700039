
const state = {
    data: 1,
    is_loading: false,
    token: '',
    menu: [],
    organization: [],
    db: '',
    is_develop: false,
    current_menu: null
};

export default state;
