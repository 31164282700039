// 启用状态
const staring_use = [
    {
        value: 0,
        title: '禁用',
    },
    {
        value: 1,
        title: '启用',
    },
    // {
    //     value: '',
    //     title: '无',
    // },
];
const staring_use_arr = [ '禁用', '启用'];

// 单据格式
const order_types = [
    {
        value: 1,
        title: '税率格式',
    },
    {
        value: 2,
        title: '折扣格式',
    },
    {
        value: 3,
        title: '普通格式',
    },
    {
        value: 4,
        title: '全部格式',
    },
    {
        value:  '',
        title: '无',
    },
    {
        value:  0,
        title: '无',
    },
];
const order_types_arr = [ '无', '税率格式', '折扣格式', '普通格式', '全部格式'];

// 单位
const unit_list = [
    {
        value: 1,
        title: '吨',
    },
    {
        value: 2,
        title: '箱',
    },
    {
        value: 3,
        title: '件',
    },
    {
        value: 4,
        title: '听',
    },
    {
        value: 5,
        title: '瓶',
    },
    {
        value: 6,
        title: '包',
    },
    {
        value: 7,
        title: 'KG',
    },
];

// 是否
const isOrno = [
    {
        value: 0,
        title: '否',
    },
    {
        value: 1,
        title: '是',
    },
];
const isOrno_arr = ['否', '是'];

// 单据状态
const bill_status = [
    {
        value: 0,
        title: '未处理',
    },
    {
        value: 1,
        title: '已生单',
    },
    {
        value: 2,
        title: '已引用',
    },
    {
        value: 3,
        title: '已完成',
    },
    {
        value: 4,
        title: '已中止',
    },
    {
        value: 5,
        title: '已删除',
    },
];
const bill_status_arr = ['未处理', '已生单', '已引用', '已完成', '已中止', '已删除'];
// 单据状态1
const bill_status1 = [
    {
        value: 0,
        title: '未处理',
    },
    {
        value: 1,
        title: '已生单',
    },
    {
        value: 2,
        title: '已引用',
    },
    {
        value: 3,
        title: '已完成',
    },
    {
        value: 4,
        title: '已中止',
    },
];
const bill_status_arr1 = ['未处理', '已生单', '已引用', '已完成', '已中止'];
// 单状态2
const bill_status2 = [
    {
        value: 0,
        title: '未处理',
    },
    {
        value: 1,
        title: '已生单草稿',
    },
    {
        value: 2,
        title: '已删除',
    },
    {
        value: 3,
        title: '已过账',
    },
    {
        value: 4,
        title: '已红冲',
    },
];
const bill_status_arr2 = ['未处理', '已生单草稿', '已删除', '已过账', '已红冲'];
// 单状态3
const bill_status3 = [
    {
        value: 0,
        title: '未处理',
    },
    {
        value: 1,
        title: '草稿',
    },
    {
        value: 2,
        title: '已删除',
    },
];
const bill_status_arr3 = ['未处理', '草稿', '已删除'];

// 客户数据类型
const customerType = [
    {
        value: 1,
        title: '客户',
    },
    {
        value: 2,
        title: '供应商',
    },
];
const customerType_arr = ['', '客户','供应商'];

const data_origin = {
    INPUT_OUTINTF: '外部接口传入',
    INPUT_ERP: 'ERP传入',
    INPUT_ZHONGTAI: '中台新增',
    INPUT_DMS: 'DMS',
    INPUT_WMS: 'B-WMS',
    INPUT_APP: '耀灵APP',
};

// 单据来源
const order_source = [
    {
        value: 1,
        title: '总部',
    },
    {
        value: 2,
        title: '组织',
    },
    {
        value: 3,
        title: '其它',
    },
];

const order_source_arr = ['', '总部', '组织', '其它'];

// 结算状态
const settlement_status = [
    {
        value: 0,
        title: '未完成结算',
    },
    {
        value: 1,
        title: '完成结算',
    },
];

const settlement_status_arr = ['未完成结算', '完成结算'];

// 订单类型
const order_type_arr = ['工厂订单', '供应商订单'];
// 营销类型
const marketing_type_arr = ['线上订货', '工厂直营', '经销商活动'];
// 单据分类
const order_classes_arr = ['', '报损单', '报溢单'];
// 
const car_status_arr = ['未分派', '未进厂', '装货中', '已出厂电签', '客户已签收', '在途', '已转出', '已送达'];

// 操作状态
const operate_statu = [
    {
        title: '失败',
        value: 0
    },
    {
        title: '成功',
        value: 1
    },
];

// 操作状态
const operate_statu_arr = ['失败', '成功'];

// 调用系统
const use_system_obj = {
    INPUT_ZHONGTAI: '盈中台',
    INPUT_ERP: 'A8',
    INPUT_DMS: 'dms',
    INPUT_WMS: 'b-wms',
    INPUT_TMS: 'TMS',
    INPUT_SAP: 'SAP',
};

// 调用系统
const use_system = [
    {
        title: '盈中台',
        value: 'INPUT_ZHONGTAI',
    },
    {
        title: 'A8',
        value: 'INPUT_ERP',
    },
    {
        title: 'dms',
        value: 'INPUT_DMS',
    },
    {
        title: 'b-wms',
        value: 'INPUT_WMS',
    },
    {
        title: 'TMS',
        value: 'INPUT_TMS',
    },
    {
        title: 'SAP',
        value: 'INPUT_SAP',
    },
];

export {
    staring_use,
    staring_use_arr,
    order_types,
    order_types_arr,
    unit_list,
    isOrno,
    isOrno_arr,
    bill_status,
    bill_status_arr,
    bill_status1,
    bill_status_arr1,
    bill_status2,
    bill_status_arr2,
    bill_status3,
    bill_status_arr3,
    data_origin,
    customerType,
    customerType_arr,
    order_source,
    order_source_arr,
    settlement_status,
    settlement_status_arr,
    order_type_arr,
    marketing_type_arr,
    order_classes_arr,
    car_status_arr,
    operate_statu,
    operate_statu_arr,
    use_system_obj,
    use_system,
};
