// 角色管理
import { staring_use } from '../../../api/common';
const Role = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', width: 80, minWidth: 80,pinned: 'left', hide: false, cellRenderer: "filter_rowNumber"},
        { headerName: '角色名称', field: 'roleName', hide: false, minWidth: 150},
        { headerName: '角色编号', field: 'roleCode', hide: false, minWidth: 150},
        { headerName: '创建时间', field: 'createTime', minWidth: 250, hide: false},
        { headerName: '修改时间', field: 'updateTime', minWidth: 250, hide: false},
        { headerName: '修改人', field: 'updatorName', hide: false},
        { headerName: '状态', field: 'status', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'staring_use_arr'}},
        { headerName: '备注', field: 'roleDescription', hide: false, minWidth: 250},
        { headerName: '操作', field: '', pinned: 'right' , cellRenderer: 'operate_element', hide: false, width: 150, minWidth: 150},
    ],
    modalList: [
        {
            title: '角色名称',
            value: '',
            key: 'roleName',
            is_required: true,
            component: 'StextInput'
        },
        {
            title: '角色编号',
            value: '',
            key: 'roleCode',
            is_required: true,
            component: 'StextInput'
        },
        {
            title: '状态',
            value: 1,
            key: 'status',
            component: 'Sselect',
            is_required: true,
            select_list: staring_use
        },
        {
            title: '备注',
            value: '',
            key: 'roleDescription',
            width: '410px',
            component: 'StextAreaInput',
        },
    ],
    left_menu_list: [
        {
            title: '首页',
            key: 'home',
            children: []
        },
        {
            title: '主数据中心',
            key: 'base',
            children: [
                {
                    title: '组织信息',
                    key: 'SUB_GROUP',
                    checkbox_title: '全选',
                    checkbox_val: false,
                },
                {
                    title: '物料信息',
                    key: 'SUB_COMMODITY',
                    checkbox_title: '全选',
                    checkbox_val: false,
                },
                {
                    title: '物料定价信息',
                    key: 'SUB_DEALER',
                    checkbox_title: '全选',
                    checkbox_val: false,
                },
                {
                    title: '组织关系',
                    key: 'SUB_CUSTOMER',
                    checkbox_title: '全选',
                    checkbox_val: false,
                },
            ]
        },
        {
            title: '业务中心',
            key: 'bill',
            children: [
                {
                    title: '销售单据',
                    key: 'SUB_SALES',
                },
                {
                    title: '采购单据',
                    key: 'SUB_PURCHASE'
                },
                {
                    title: '仓库单据',
                    key: 'BUSINESS_WAREHOUSE'
                },
                {
                    title: '往来单据',
                    key: 'BUSINESS_RETUREBACK'
                },
            ]
        },
        {
            title: '技术中心',
            key: 'TECHNOLOGY_NAKADAI',
            children: []
        },
        {
            title: '数据中心',
            key: 'DATA_NAKADAI_BI',
            children: []
        },
        {
            title: '配置中心',
            key: 'system',
            children: []
        },
    ],
    checkAll_header: [
        {
            name: '查看(全选)',
            isChockbox: true,
            type: 'search',
            value: false
        },
        {
            name: '新增/保存(全选)',
            isChockbox: true,
            type: 'add',
            value: false
        },
        {
            name: '删除(全选)',
            isChockbox: true,
            type: 'delete',
            value: false
        },
        {
            name: '修改(全选)',
            isChockbox: true,
            type: 'edit',
            value: false
        },
        {
            name: '导出(全选)',
            isChockbox: true,
            type: 'export',
            value: false
        },
        {
            name: '导入、下载模板(全选)',
            isChockbox: true,
            type: 'print',
            value: false
        },
        {
            name: '其它(全选)',
            isChockbox: true,
            type: 'other',
            value: false
        },
    ],
    role_code_list: [
        {
            title: '首页',
            isShow: true,
            func: 'home',
            header: [
                {
                    name: '功能点'
                },
                {
                    name: '查看',
                    isChockbox: true,
                    type: 'search',
                    value: false
                },
                {
                    name: '新增/保存',
                    isChockbox: true,
                    type: 'add',
                    value: false
                },
                {
                    name: '删除',
                    isChockbox: true,
                    type: 'delete',
                    value: false
                },
                {
                    name: '修改',
                    isChockbox: true,
                    type: 'edit',
                    value: false
                },
                {
                    name: '导出',
                    isChockbox: true,
                    type: 'export',
                    value: false
                },
                {
                    name: '导入、下载模板',
                    isChockbox: true,
                    type: 'print',
                    value: false
                },
                {
                    name: '其它',
                    isChockbox: true,
                    type: 'other',
                    value: false
                },
            ],
            code_list: [
                {
                    parent: ['home'],
                    children: [
                        {
                            name: "首页设置"
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                }
            ]
        },
        {
            title: '主数据中心',
            isShow: false,
            func: 'base',
            header: [
                {
                    name: '功能点'
                },
                {
                    name: '查看',
                    isChockbox: true,
                    type: 'search',
                    value: false
                },
                {
                    name: '新增/保存',
                    isChockbox: true,
                    type: 'add',
                    value: false
                },
                {
                    name: '删除',
                    isChockbox: true,
                    type: 'delete',
                    value: false
                },
                {
                    name: '修改',
                    isChockbox: true,
                    type: 'edit',
                    value: false
                },
                {
                    name: '导出',
                    isChockbox: true,
                    type: 'export',
                    value: false
                },
                {
                    name: '导入、下载模板',
                    isChockbox: true,
                    type: 'print',
                    value: false
                },
                {
                    name: '其它',
                    isChockbox: true,
                    type: 'other',
                    value: false
                },
            ],
            code_list: [
                {
                    parent: ['SUB_GROUP'],
                    children: [
                        {
                            name: "组织档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_ORGA_INFO', 'USER_ORGA_INFO_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_ORGA_INFO_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_ORGA_INFO_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_ORGA_INFO_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_GROUP'],
                    children: [
                        {
                            name: "组织架构档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_ORIGANSTRUCT_ARCHIVE','USER_ORIGANSTRUCT_ARCHIVE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_ORIGANSTRUCT_ARCHIVE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_ORIGANSTRUCT_ARCHIVE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_ORIGANSTRUCT_ARCHIVE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_GROUP'],
                    children: [
                        {
                            name: "分公司档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_COMPANY_ARCHIVE', 'USER_COMPANY_ARCHIVE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_COMPANY_ARCHIVE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_COMPANY_ARCHIVE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_COMPANY_ARCHIVE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_GROUP'],
                    children: [
                        {
                            name: "大区档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_REGION_ARCHIVE','USER_REGION_ARCHIVE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_REGION_ARCHIVE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_REGION_ARCHIVE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_REGION_ARCHIVE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_GROUP'],
                    children: [
                        {
                            name: "办事处档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_OFFICE_ARCHIVE','USER_OFFICE_ARCHIVE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_OFFICE_ARCHIVE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_OFFICE_ARCHIVE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_OFFICE_ARCHIVE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_GROUP'],
                    children: [
                        {
                            name: "渠道档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CHANNEL_ARCHIVE','USER_CHANNEL_ARCHIVE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CHANNEL_ARCHIVE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CHANNEL_ARCHIVE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CHANNEL_ARCHIVE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_GROUP'],
                    children: [
                        {
                            name: "组织组档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_ORIGANGROUP_ARCHIVE','USER_ORIGANGROUP_ARCHIVE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_ORIGANGROUP_ARCHIVE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_ORIGANGROUP_ARCHIVE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_ORIGANGROUP_ARCHIVE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_COMMODITY'],
                    children: [
                        {
                            name: "商品定价组档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSPRICE_ARCHIVE','USER_GOODSPRICE_ARCHIVE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSPRICE_ARCHIVE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSPRICE_ARCHIVE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSPRICE_ARCHIVE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_COMMODITY'],
                    children: [
                        {
                            name: "总部物料档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_HEADGOODS_INFO','USER_HEADGOODS_INFO_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_HEADGOODS_INFO_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_HEADGOODS_INFO_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_HEADGOODS_INFO_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_COMMODITY'],
                    children: [
                        {
                            name: "物料单位档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSUNIT_INFO','USER_GOODSUNIT_INFO_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSUNIT_INFO_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSUNIT_INFO_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSUNIT_INFO_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_COMMODITY'],
                    children: [
                        {
                            name: "物料类别档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSTYPE_INFO','USER_GOODSTYPE_INFO_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSTYPE_INFO_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSTYPE_INFO_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSTYPE_INFO_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_COMMODITY'],
                    children: [
                        {
                            name: "物料大类档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSBTYPE_ARCHIVE','USER_GOODSBTYPE_ARCHIVE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSBTYPE_ARCHIVE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSBTYPE_ARCHIVE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSBTYPE_ARCHIVE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_COMMODITY'],
                    children: [
                        {
                            name: "物料中类档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSMTYPE_ARCHIVE','USER_GOODSMTYPE_ARCHIVE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSMTYPE_ARCHIVE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSMTYPE_ARCHIVE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSMTYPE_ARCHIVE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_COMMODITY'],
                    children: [
                        {
                            name: "物料小类档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSSTYPE_ARCHIVE','USER_GOODSSTYPE_ARCHIVE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSSTYPE_ARCHIVE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSSTYPE_ARCHIVE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GOODSSTYPE_ARCHIVE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_DEALER'],
                    children: [
                        {
                            name: "组织单品定价组"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_CUSTSPROD_TABLE','USER_DEALERPRICE_CUSTSPROD_TABLE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_CUSTSPROD_TABLE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_CUSTSPROD_TABLE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_CUSTSPROD_TABLE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_DEALER'],
                    children: [
                        {
                            name: "组织物料结构定价组"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_GPSTRUCT_TABLE','USER_DEALERPRICE_GPSTRUCT_TABLE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_GPSTRUCT_TABLE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_GPSTRUCT_TABLE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_GPSTRUCT_TABLE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_DEALER'],
                    children: [
                        {
                            name: "组织架构单品定价组"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_OSINGLE_TABLE','USER_DEALERPRICE_OSINGLE_TABLE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_OSINGLE_TABLE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_OSINGLE_TABLE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_OSINGLE_TABLE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_DEALER'],
                    children: [
                        {
                            name: "组织架构物料结构定价组"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_OSTRUCT_TABLE','USER_DEALERPRICE_OSTRUCT_TABLE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_OSTRUCT_TABLE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_OSTRUCT_TABLE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEALERPRICE_OSTRUCT_TABLE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_DEALER'],
                    children: [
                        {
                            name: "客户单品定价组"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CSINGLE','USER_CLIENT_PTABLE_CSINGLE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CSINGLE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CSINGLE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CSINGLE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_DEALER'],
                    children: [
                        {
                            name: "客户物料结构定价组"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CPSTRUCT','USER_CLIENT_PTABLE_CPSTRUCT_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CPSTRUCT_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CPSTRUCT_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CPSTRUCT_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_DEALER'],
                    children: [
                        {
                            name: "客户架构单品定价组"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CSSINGLE','USER_CLIENT_PTABLE_CSSINGLE_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CSSINGLE_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CSSINGLE_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CSSINGLE_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_DEALER'],
                    children: [
                        {
                            name: "客户架构物料结构定价组"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CSPSTRUCT','USER_CLIENT_PTABLE_CSPSTRUCT_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CSPSTRUCT_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CSPSTRUCT_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_PTABLE_CSPSTRUCT_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_CUSTOMER'],
                    children: [
                        {
                            name: "组织物料档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DGOODS_INFO','USER_DGOODS_INFO_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DGOODS_INFO_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DGOODS_INFO_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DGOODS_INFO_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_CUSTOMER'],
                    children: [
                        {
                            name: "组织物料关系档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GROUP_MATERIALS_RELA','USER_GROUP_MATERIALS_RELA_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GROUP_MATERIALS_RELA_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GROUP_MATERIALS_RELA_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GROUP_MATERIALS_RELA_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_CUSTOMER'],
                    children: [
                        {
                            name: "组织物料授权组档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GROUP_MATERIALS_AUTH','USER_GROUP_MATERIALS_AUTH_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GROUP_MATERIALS_AUTH_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GROUP_MATERIALS_AUTH_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_GROUP_MATERIALS_AUTH_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_CUSTOMER'],
                    children: [
                        {
                            name: "组织客户档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_INFO','USER_CLIENT_INFO_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_INFO_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_INFO_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_CLIENT_INFO_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_CUSTOMER'],
                    children: [
                        {
                            name: "组织职员档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_EMPL_INFO','USER_EMPL_INFO_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_EMPL_INFO_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_EMPL_INFO_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_EMPL_INFO_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_CUSTOMER'],
                    children: [
                        {
                            name: "组织部门档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEPT_INFO','USER_DEPT_INFO_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEPT_INFO_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEPT_INFO_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_DEPT_INFO_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['SUB_CUSTOMER'],
                    children: [
                        {
                            name: "组织仓库档案"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_INVE_INFO','USER_INVE_INFO_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_INVE_INFO_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_INVE_INFO_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['USER_INVE_INFO_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
            ]
        },
        {
            title: '业务中心',
            isShow: false,
            func: 'bill',
            header: [
                {
                    name: '功能点'
                },
                {
                    name: '查看',
                    isChockbox: true,
                    type: 'search',
                    value: false
                },
                {
                    name: '新增/保存',
                    isChockbox: true,
                    type: 'add',
                    value: false
                },
                {
                    name: '删除',
                    isChockbox: true,
                    type: 'delete',
                    value: false
                },
                {
                    name: '修改',
                    isChockbox: true,
                    type: 'edit',
                    value: false
                },
                {
                    name: '导出',
                    isChockbox: true,
                    type: 'export',
                    value: false
                },
                {
                    name: '导入、下载模板',
                    isChockbox: true,
                    type: 'print',
                    value: false
                },
                {
                    name: '其它',
                    isChockbox: true,
                    type: 'other',
                    value: false
                },
            ],
            code_list: [
                {
                    parent: ['SUB_SALES'],
                    children: [
                        {
                            name: "销售订单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_SALE_ORDER','BUSINESS_SALE_ORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_SALE_ORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['SUB_SALES'],
                    children: [
                        {
                            name: "销售退货单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_INPUT_ORDER','BUSINESS_INPUT_ORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_INPUT_ORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['SUB_SALES'],
                    children: [
                        {
                            name: "销售出库单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_DELIVERY_ORDER','BUSINESS_DELIVERY_ORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_DELIVERY_ORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['SUB_SALES'],
                    children: [
                        {
                            name: "销售换货单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_SWAP_NUM','BUSINESS_SWAP_NUM_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_SWAP_NUM_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['SUB_PURCHASE'],
                    children: [
                        {
                            name: "采购订单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_PURCHASE_ORDER','BUSINESS_PURCHASE_ORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_PURCHASE_ORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['SUB_PURCHASE'],
                    children: [
                        {
                            name: "请购单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_PBUY_ORDER','BUSINESS_PBUY_ORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_PBUY_ORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['SUB_PURCHASE'],
                    children: [
                        {
                            name: "采购入库单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_PURCHAES_INPUTORDER','BUSINESS_PURCHAES_INPUTORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_PURCHAES_INPUTORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['SUB_PURCHASE'],
                    children: [
                        {
                            name: "采购退货单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_PRETURN_ORDER','BUSINESS_PRETURN_ORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_PRETURN_ORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['SUB_PURCHASE'],
                    children: [
                        {
                            name: "采购换货单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_PSWAP_ORDER','BUSINESS_PSWAP_ORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_PSWAP_ORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['BUSINESS_WAREHOUSE'],
                    children: [
                        {
                            name: "同价调拨单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_CTRANSFER_ORDER','BUSINESS_CTRANSFER_ORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_CTRANSFER_ORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['BUSINESS_WAREHOUSE'],
                    children: [
                        {
                            name: "其它入库单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_OTHERINPUT_ORDER','BUSINESS_OTHERINPUT_ORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_OTHERINPUT_ORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['BUSINESS_WAREHOUSE'],
                    children: [
                        {
                            name: "其它出库单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_OTHEROUTPUT_ORDER','BUSINESS_OTHEROUTPUT_ORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_OTHEROUTPUT_ORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['BUSINESS_WAREHOUSE'],
                    children: [
                        {
                            name: "库存分析"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_INVENTORY_CENSUS','BUSINESS_INVENTORY_CENSUS_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['BUSINESS_RETUREBACK'],
                    children: [
                        {
                            name: "收款单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_GATHERING_ORDER','BUSINESS_GATHERING_ORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_GATHERING_ORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['BUSINESS_RETUREBACK'],
                    children: [
                        {
                            name: "付款单"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_PAYMENT_ORDER','BUSINESS_PAYMENT_ORDER_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_PAYMENT_ORDER_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
                {
                    parent: ['BUSINESS_RETUREBACK'],
                    children: [
                        {
                            name: "往来对账"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_CONTACTS_RECON','BUSINESS_CONTACTS_RECON_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['BUSINESS_CONTACTS_RECON_ISSUE'],
                            type: 'other',
                            name: '单据下发'
                        },
                    ]
                },
            ]
        },
        {
            title: '技术中心',
            isShow: false,
            func: 'TECHNOLOGY_NAKADAI',
            header: [
                {
                    name: '功能点'
                },
                {
                    name: '查看',
                    isChockbox: true,
                    type: 'search',
                    value: false
                },
                {
                    name: '新增/保存',
                    isChockbox: true,
                    type: 'add',
                    value: false
                },
                {
                    name: '删除',
                    isChockbox: true,
                    type: 'delete',
                    value: false
                },
                {
                    name: '修改',
                    isChockbox: true,
                    type: 'edit',
                    value: false
                },
                {
                    name: '导出',
                    isChockbox: true,
                    type: 'export',
                    value: false
                },
                {
                    name: '导入、下载模板',
                    isChockbox: true,
                    type: 'print',
                    value: false
                },
                {
                    name: '其它',
                    isChockbox: true,
                    type: 'other',
                    value: false
                },
            ],
            code_list: [
                {
                    parent: ['TECHNOLOGY_NAKADAI_MONITOR'],
                    children: [
                        {
                            name: "服务器监控"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['TECHNOLOGY_NAKADAI_SERVERMONITOR','TECHNOLOGY_NAKADAI_SERVERMONITOR_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['TECHNOLOGY_NAKADAI_MONITOR'],
                    children: [
                        {
                            name: "API监控"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['TECHNOLOGY_NAKADAI_APIMONITOR','TECHNOLOGY_NAKADAI_SERVERMONITOR_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['TECHNOLOGY_NAKADAI_MONITOR'],
                    children: [
                        {
                            name: "ERP监控"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['TECHNOLOGY_NAKADAI_ERPMONITOR','TECHNOLOGY_NAKADAI_SERVERMONITOR_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
            ]
        },
        {
            title: '数据中心',
            isShow: false,
            func: 'DATA_NAKADAI_BI',
            header: [
                {
                    name: '功能点'
                },
                {
                    name: '查看',
                    isChockbox: true,
                    type: 'search',
                    value: false
                },
                {
                    name: '新增/保存',
                    isChockbox: true,
                    type: 'add',
                    value: false
                },
                {
                    name: '删除',
                    isChockbox: true,
                    type: 'delete',
                    value: false
                },
                {
                    name: '修改',
                    isChockbox: true,
                    type: 'edit',
                    value: false
                },
                {
                    name: '导出',
                    isChockbox: true,
                    type: 'export',
                    value: false
                },
                {
                    name: '导入、下载模板',
                    isChockbox: true,
                    type: 'print',
                    value: false
                },
                {
                    name: '其它',
                    isChockbox: true,
                    type: 'other',
                    value: false
                },
            ],
            code_list: [
                {
                    parent: ['DATA_NAKADAI_BI_REPORT'],
                    children: [
                        {
                            name: "库存分析"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['DATA_NAKADAI_BI_ANALYSIS','TECHNOLOGY_NAKADAI_SERVERMONITOR_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
            ]
        },
        {
            title: '配置中心',
            isShow: false,
            func: 'system',
            header: [
                {
                    name: '功能点'
                },
                {
                    name: '查看',
                    isChockbox: true,
                    type: 'search',
                    value: false
                },
                {
                    name: '新增/保存',
                    isChockbox: true,
                    type: 'add',
                    value: false
                },
                {
                    name: '删除',
                    isChockbox: true,
                    type: 'delete',
                    value: false
                },
                {
                    name: '修改',
                    isChockbox: true,
                    type: 'edit',
                    value: false
                },
                {
                    name: '导出',
                    isChockbox: true,
                    type: 'export',
                    value: false
                },
                {
                    name: '导入、下载模板',
                    isChockbox: true,
                    type: 'print',
                    value: false
                },
                {
                    name: '其它',
                    isChockbox: true,
                    type: 'other',
                    value: false
                },
            ],
            code_list: [
                {
                    parent: ['system'],
                    children: [
                        {
                            name: "角色管理"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['system1','system2_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['system2_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['system2_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['system2_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['system'],
                    children: [
                        {
                            name: "操作员管理"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['system2','system2_SELECT'],
                            type: 'search'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['system2_INSERT'],
                            type: 'add'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['system2_DELETE'],
                            type: 'delete'
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['system2_UPDATE'],
                            type: 'edit'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
                {
                    parent: ['system'],
                    children: [
                        {
                            name: "日志"
                        },
                        {
                            isChockbox: true,
                            value: false,
                            code: ['system3','system3_SELECT'],
                            type: 'search'
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                        {
                            name: ""
                        },
                    ]
                },
            ]
        },
    ],
};

export default Role;