import styled from 'styled-components';
import globalStyle from '../assets/global-style';

const IndexWrapper = styled.div`
    .header{
        background-image: linear-gradient(${globalStyle['theme-color']}, #3585b1);
        color: white;
        height: 50px;
        display: flex;
        line-height: 50px;
        padding: 0 20px;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        ._self_selet{
            width: 400px;
            margin: auto 30px;
            .ant-select-selector{
                border-radius: 30px;
            }
        }
        .right_content{
            display: flex;
            .headerImage{
                margin: 0 10px;
                .user_role_header{
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    vertical-align: middle;
                    box-shadow: 0 0 10px white;
                }
            }
        }
        .right_content:before{
            content: '';
            width: 195px;
            height: 2px;
            background-color: #75b757;
            position: absolute;
            right: 10px;
            bottom: 2px;
            border-radius: 5px;
            box-shadow: 0 0 20px #75b757;
        }
        /* .right_content{
            display: flex;
            box-shadow: 0 0 10px white;
            border-radius: 20px;
            height: 40px;
            margin: auto 0;
            padding: 0 10px;
            .user_meassge{
                padding: 0 10px;
                font-size: 14px;
            }
            .headerImage{
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
            .user_role_header{
                height: 30px;
                width: 30px;
                border-radius: 50%;
            }
            .setting{
                color: white;
                margin-right: 10px;
                .set_content_wrapper{
                    .set_item{
                        cursor: pointer;
                        border-bottom: 1px solid ${globalStyle['theme-color']};
                        text-align: center;
                        padding: 10px 0;
                    }
                }
            }
        } */
        .system_name{
            font-size: 16px;
            img{
                height: 20px;
                vertical-align: middle;
                padding-right: 10px;
            }
        }
    }
    .contentes{
        display: flex;
        .menu_wrapper{
            background-color: ${globalStyle['menu-color']};
            height: calc(100vh - 50px);
            overflow: auto;
            .ant-menu-dark .ant-menu-inline.ant-menu-sub{
                background-color: ${globalStyle['menu-color']};
            }
            /* .ant-menu.ant-menu-dark{
                background-color: ${globalStyle['menu-color']};
            }
            .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub{
                background-color: ${globalStyle['menu-color']};
            }
            .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
                background-color: ${globalStyle['menu-selected-color']};
            } */
            .main_icon{
                font-weight: bolder;
                text-align: center;
                color: white;
                padding-top: 10px;
                cursor:pointer;
                .iconfont{
                    font-size: 18px;
                }
            }
            .ant-menu-inline-collapsed{
                width: 60px;
            }
        }
        .menu_wrapper::-webkit-scrollbar {
            width: 7px;
        }

        .menu_wrapper::-webkit-scrollbar-track {
            background-color: #f1f1f1;
            -webkit-border-radius: 2em;
            -moz-border-radius: 2em;
            border-radius: 2em;
        }

        .menu_wrapper::-webkit-scrollbar-thumb {
            background-color: #c1c1c1;
            -webkit-border-radius: 2em;
            -moz-border-radius: 2em;
            border-radius: 2em;
        }

        .right_content{
            flex: 1;
            .tag_wrapper{
                width: 100%;
                padding: 5px 10px;
                background-color: white;
                border-bottom: 1px solid #c1c1c1;
                cursor: pointer;
                position: relative;
                .self_tag{
                    padding: 5px 0 5px 10px;
                    border-radius: 5px;
                    border: 1px solid #3ba9be;
                    font-size: 13px;
                    cursor: pointer;
                    margin-right: 10px;
                    .iconicon_guanbi{
                        padding: 0 5px;
                        font-size: 12px;
                    }
                }
                .swiper-slide{
                    width: auto;
                }
                .active_tag{
                    background-color: #2daabd;
                    border-color: #3ba9be;
                    color: white;
                    box-shadow: 0 0 10px #2daabd;
                }
                .contentMenu_content{
                    position: absolute;
                    border-radius: 5px;
                    box-shadow: 0 0 10px ${globalStyle['theme-color']};
                    background-color: white;
                    padding: 10px;
                    z-index: 11;
                    font-size: 14px;
                    width: 150px;
                    cursor: pointer;
                    .contentMenu_item{
                        padding: 10px 0;
                    }
                    .contentMenu_footer{
                        border-top: 1px solid ${globalStyle['theme-color1']};
                        color: ${globalStyle['theme-color1']};
                        text-align: right;
                    }
                }
            }
            ._view_wrapper{
                width: 100%;
                height: calc(100vh - 90px);
                overflow: auto;
                background-color: white;
            }
            .swiper-container{
                height: 28px;
            }
        }
   
    .ant-btn-primary{
        background-color: ${globalStyle["theme-color"]} !important;
        border-color: ${globalStyle["theme-color"]} !important;
    }
    .ant-btn{
        height: 30px;
        margin-right: 10px;
        padding: 4px 10px;
        font-size: 12px;
    }
    .checkbox_item{
            margin: 10px 0;
    }
    .page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

`;

export default IndexWrapper;
