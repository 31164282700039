import defaultState from './state';
import * as types from './actionType';
import produce from 'immer';

const state = (state = defaultState, action) => {
    switch(action.type) {
        case types.CREATE_DB: 
            return produce(state, draft => {
                draft.db = action.value
            });
        case types.CHANGE_DATA: 
            return produce(state, draft => {
                draft.data = action.value
            });
        case types.CHANGE_IS_LOADING: 
            return produce(state, draft => {
                draft.is_loading = action.value
            });
        case types.CHANGE_TOCKEN: 
            return produce(state, draft => {
                draft.token = action.value
            });
        case types.CHANGE_MENU: 
            return produce(state, draft => {
                draft.menu = action.value
            });
        case types.CHANGE_ORGANIZATION: 
            return produce(state, draft => {
                draft.organization = action.value
            });
        case types.CHANGE_IS_DEVELOP: 
            return produce(state, draft => {
                draft.is_develop = action.value
            });
        case types.CHANGE_CURRENT_MENU: 
            return produce(state, draft => {
                draft.current_menu = action.value
            });
        default: 
            return state;
    }
};

export default state;
