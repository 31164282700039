// 委托代销退货单

const entrustConsignmentReturnOrder = {
    columnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 100, pinned: 'left', hide: false, headerCheckboxSelection: true, checkboxSelection: true, cellRenderer: "filter_rowNumber"},
        { headerName: '单据编号', field: 'billNumber', hide: false, minWidth: 300},
        { headerName: '单据日期', field: 'billDate', hide: false, minWidth: 200},
        { headerName: '组织', field: 'organizationName', hide: false, minWidth: 200},
        { headerName: '账套', field: 'accsName', hide: false},
        { headerName: '客户', field: 'customerName', hide: false, minWidth: 200},
        { headerName: '职员', field: 'staffName', hide: false},
        { headerName: '仓库', field: 'warehouseName', hide: false},
        { headerName: '部门', field: 'departmentName', hide: false},
        { headerName: '单据状态', field: 'billReturnState', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'bill_status_arr'}},
        { headerName: '单据来源', field: 'billSource', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'data_origin'}},
        { headerName: '单据摘要', field: 'Explain', hide: false, minWidth: 200},
        { headerName: '原销售出库单号', field: 'orderSaleId', hide: false},
        { headerName: '优惠金额', field: 'discountAmount', hide: false},
        { headerName: '收付款账户', field: 'amountAccount', hide: false},
        { headerName: '收付款金额', field: 'amount', hide: false},
        { headerName: '结算客户', field: 'jbtypename', hide: false},
        { headerName: '制单人', field: 'inetypename', hide: false},
        { headerName: '修改人', field: 'upetypename', hide: false},
        { headerName: '送达时间', field: 'reachdate', hide: false, minWidth: 200},
        { headerName: '送达地址', field: 'deliveryPartAddress', hide: false},
        { headerName: '是否下发', field: 'isNeedSend', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '车牌号', field: 'licensePlate', hide: false},
        { headerName: '司机', field: 'driver', hide: false},
        { headerName: '创建时间', field: 'gmtCreate', hide: false, minWidth: 200},
        { headerName: '修改时间', field: 'gmtModified', hide: false, minWidth: 200},
        { headerName: '备注', field: 'remarks', hide: false, minWidth: 200},
    ],
    DetailColumnDefs: [
        { headerName: '行号', field: 'rowNumber', minWidth: 0, width: 80, pinned: 'left', hide: false, cellRenderer: "filter_rowNumber", lockPosition: true}, // 
        { headerName: '商品名称', field: 'materielName', hide: false, minWidth: 200},
        { headerName: '总部商品名称', field: 'itemHeadName', hide: false, minWidth: 200},
        { headerName: '商品采购销售单位', field: 'materielOrderSaleName', hide: false, minWidth: 200},
        { headerName: '商品采购销售数量', field: 'materielOrderSaleQuantity', hide: false, minWidth: 200},
        { headerName: '税率', field: 'taxRate', hide: false},
        { headerName: '折扣率', field: 'discount', hide: false},
        { headerName: '折扣合计', field: 'priceTaxTotal', hide: false},
        { headerName: '含税单价', field: 'priceTaxIncluding', hide: false},
        { headerName: '税额', field: 'tax', hide: false},
        { headerName: '批号', field: 'batchNumber', hide: false},
        { headerName: '状态', field: 'state', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'marketing_type_arr'}},
        { headerName: '销售单位不含税单价', field: 'price', hide: false, minWidth: 160},
        { headerName: '销售单位不含税总价', field: 'totalprice', hide: false, minWidth: 160},
        { headerName: '销售单位折扣单价', field: 'discountprice', hide: false, minWidth: 150},
        { headerName: '销售单位折扣总价', field: 'discounttotalprice', hide: false, minWidth: 150},
        { headerName: '备注', field: 'comment', hide: false, minWidth:250},
        { headerName: '是否赠品', field: 'isgift', hide: false, cellRenderer: "filter_cell_show", cellRendererParams: {filter_key: 'isOrno_arr'}},
        { headerName: '品级', field: 'grade', hide: false},
        { headerName: '年限', field: 'years', hide: false},
        { headerName: '重量', field: 'weignt', hide: false},
    ],
    searchList: [
        {
            title: '单据编号',
            value: '',
            key: 'billNumber',
            label_width: '100px',
            component: 'StextInput'
        },
        {
            label_title: '账套',
            value: '',
            show_value: '',
            key: 'accsCode',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'single',
            component: 'AccountInput'
        },
        {
            label_title: '组织',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'organizationId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'OrganizationInput'
        },
        {
            label_title: '客户',
            value: '',
            show_value: '',
            accsCode: '',
            modal_title: '客户',
            key: 'customerId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'CustomerInput'
        },
        {
            label_title: '职员',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'staffId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'StaffInput'
        },
        {
            label_title: '仓库',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'warehouseId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'WarehouseInput'
        },
        {
            label_title: '部门',
            value: '',
            show_value: '',
            accsCode: '',
            key: 'departmentId',
            show_key: 'name',
            data_key: 'ukey',
            is_auto: false,
            isRow_selection: true,
            label_width: '100px',
            selectType: 'multiple',
            component: 'DepartmentInput'
        },
        {
            title: '创建查询时段',
            value: [],
            key: 'search_creatTime',
            label_width: '100px',
            component: 'Stime'
        },
        {
            title: '修改查询时段',
            value: [],
            key: 'search_updateTime',
            label_width: '100px',
            component: 'Stime'
        },
    ],
};

export default entrustConsignmentReturnOrder;