import React, { lazy, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import BlankLayout from '../layouts/BlankLayout';
import IndexLayout from '../layouts/IndexLayout';
import { KeepaliveRoute } from 'react-keepalive-router';
import Loading from '../components/loadding'

const SuspenseComponent = Component => props => {
    return (
        <Suspense fallback={<Loading />}>
            <Component {...props}></Component>
        </Suspense>
    )
};

const Login = lazy(() => import('../page/login/index.js'));
const Home = lazy(() => import('../page/home/index.js'));
const ChangeParssword = lazy(() => import('../page/change_password/index.js'));
const NoFound = lazy(() => import('../page/404/index.js'));

// start
const OrganizationInformation = lazy(() => import('../page/basic_information/organization_information/index'));
const AccountInformation = lazy(() => import('../page/basic_information/account_information/index'));
const RegionInformation = lazy(() => import('../page/basic_information/region_information/index'));
const OfficeInformation = lazy(() => import('../page/basic_information/office_information/index'));
const OrganizationGroup = lazy(() => import('../page/basic_information/organization_group_information/index'));
const OrganizationFramwork = lazy(() => import('../page/basic_information/organization_framwork_information/index'));
const GoodsUnit = lazy(() => import('../page/basic_information/goods_unit_information/index'));
const GoodsClasses = lazy(() => import('../page/basic_information/goods_classes_information/index'));
const GoodsLargeClasses = lazy(() => import('../page/basic_information/goods_largeClasses_information/index'));
const GoodsSmallClasses = lazy(() => import('../page/basic_information/goods_smallClasses_information/index'));
const HeaderquarterGoods = lazy(() => import('../page/basic_information/headerquarters_goods_information/index'));
const CustomerInformation = lazy(() => import('../page/basic_information/customer_information/index'));
const DepartmentInformation = lazy(() => import('../page/basic_information/department_information/index'));
const StaffInformation = lazy(() => import('../page/basic_information/staff_information/index'));
const WarehouseInformation = lazy(() => import('../page/basic_information/warehouse_information/index'));
const CsingleProductInformation = lazy(() => import('../page/basic_information/make_price/customer_singleProduct_price/index'));
const CProductStructruePInformation = lazy(() => import('../page/basic_information/make_price/customer_productStructrue_price/index'));
const CSSPPInformation = lazy(() => import('../page/basic_information/make_price/customerStructrue_singleProduct_price/index'));
const CSPSInformation = lazy(() => import('../page/basic_information/make_price/customerStructrue_productStructrue_price/index'));
const DSPInformation = lazy(() => import('../page/basic_information/make_price/dealer_singleProduct_price/index'));
const DPSPInformation = lazy(() => import('../page/basic_information/make_price/dealer_productStructrue_price/index'));
const DSSPInformation = lazy(() => import('../page/basic_information/make_price/dealerStructrue_singleProduct_price/index'));
const DSPSPInformation = lazy(() => import('../page/basic_information/make_price/dealerStructrue_productStructrue_price/index'));
const BrandInformation = lazy(() => import('../page/basic_information/brand_information/index'));
const GoodsCorrespondingInformation = lazy(() => import('../page/basic_information/goods_corresponding_information/index'));
const GoodsRecycleInformation = lazy(() => import('../page/basic_information/goods_recycle_information/index'));
const InventorySynchronization = lazy(() => import('../page/basic_information/inventory_synchronization/index'));
// 单据
const SaleOrder = lazy(() => import('../page/business_order/sale_order/index'));
const PurchaseOrder = lazy(() => import('../page/business_order/purchase_order/index'));
const PurchaseInStorageOrder = lazy(() => import('../page/business_order/purchase_InStorage_order/index'));
const PurchaseRetrunOrder = lazy(() => import('../page/business_order/purchase_return_order/index'));
const PurchaseSwapGoodsOrder = lazy(() => import('../page/business_order/purchase_swap_goods_order/index'));
const SaleDeliveryOrder = lazy(() => import('../page/business_order/sale_delivery_order/index'));
const SaleSwapGoodsOrder = lazy(() => import('../page/business_order/sale_swap_goods_order/index'));
const SaleReturnOrder = lazy(() => import('../page/business_order/sale_return_order/index'));
const CoequalTransferOrder = lazy(() => import('../page/business_order/coequal_transfer_order/index'));
const OtherInStorageOrder = lazy(() => import('../page/business_order/other_InStorage_order/index'));
const CheckOrder = lazy(() => import('../page/business_order/check_order/index'));
const BreakageOrder = lazy(() => import('../page/business_order/breakage_order/index'));
const EntrustDeliveryOrder = lazy(() => import('../page/business_order/entrust_delivery_order/index'));
const EntrustConsignmentReturnOrder = lazy(() => import('../page/business_order/entrust_consignment_return_order/index'));
const CommissionedConsignmentReceivingOrder = lazy(() => import('../page/business_order/comissioned_consignment_receiving_order/index'));
const CommissionedConsignmentReturnOrder = lazy(() => import('../page/business_order/commissioned_consignment_return_order/index'));
const InventoryStatistics = lazy(() => import('../page/business_order/inventory_statistics/index'));
const OperatorSetting = lazy(() => import('../page/system_management/operator_setting/index'));
const RoleSetting = lazy(() => import('../page/system_management/role_setting/index'));
const OMAinformation = lazy(() => import('../page/basic_information/organization_material_authorization/index'));
const OMLinformation = lazy(() => import('../page/basic_information/organization_material_relation/index'));
const OMIinformation = lazy(() => import('../page/basic_information/organization_material_issue/index'));
const TransportInformation = lazy(() => import('../page/basic_information/transport_information/index'));
const OperateLog = lazy(() => import('../page/system_management/log/index'));
const InventoryAnalysis = lazy(() => import('../page/data_manage/inventory_analysis/index'));
const ServerMonitoring = lazy(() => import('../page/technology_manage/monitorying/server_monitoring/index'));
const ErpMonitoring = lazy(() => import('../page/technology_manage/monitorying/erp_monitoring/index'));
const ApiMonitoring = lazy(() => import('../page/technology_manage/monitorying/api_monitoring/index'));
const ApiCenter = lazy(() => import('../page/api/api_center'));

const routes = [
    {
        component: BlankLayout,
        routes: [
            {
                path: '/',
                exact: true,
                render: () => <Redirect to="/Login"></Redirect>
            },
            {
                path: '/Login',
                key: '/Login',
                component: SuspenseComponent(Login),
            },
            {
                path: '/index',
                key: '/index',
                component: SuspenseComponent(IndexLayout),
                routes: [
                    {
                        path: '/index/home',
                        key: '/index/home',
                        // component: SuspenseComponent(Home)
                        component: () => <KeepaliveRoute component={SuspenseComponent(Home)} path="/index/home" />
                    },
                    {
                        path: '/index/OrganizationInformation',
                        key: '/index/OrganizationInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(OrganizationInformation)} path="/index/OrganizationInformation" />
                    },
                    {
                        path: '/index/AccountInformation',
                        key: '/index/AccountInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(AccountInformation)} path="/index/AccountInformation" />
                    },
                    {
                        path: '/index/RegionInformation',
                        key: '/index/RegionInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(RegionInformation)} path="/index/RegionInformation" />
                    },
                    {
                        path: '/index/OfficeInformation',
                        key: '/index/OfficeInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(OfficeInformation)} path="/index/OfficeInformation" />
                    },
                    {
                        path: '/index/OrganizationGroup',
                        key: '/index/OrganizationGroup',
                        component: () => <KeepaliveRoute component={SuspenseComponent(OrganizationGroup)} path="/index/OrganizationGroup" />
                    },
                    {
                        path: '/index/OrganizationFramwork',
                        key: '/index/OrganizationFramwork',
                        component: () => <KeepaliveRoute component={SuspenseComponent(OrganizationFramwork)} path="/index/OrganizationFramwork" />
                    },
                    {
                        path: '/index/GoodsUnit',
                        key: '/index/GoodsUnit',
                        component: () => <KeepaliveRoute component={SuspenseComponent(GoodsUnit)} path="/index/GoodsUnit" />
                    },
                    {
                        path: '/index/GoodsClasses',
                        key: '/index/GoodsClasses',
                        component: () => <KeepaliveRoute component={SuspenseComponent(GoodsClasses)} path="/index/GoodsClasses" />
                    },
                    {
                        path: '/index/GoodsLargeClasses',
                        key: '/index/GoodsLargeClasses',
                        component: () => <KeepaliveRoute component={SuspenseComponent(GoodsLargeClasses)} path="/index/GoodsLargeClasses" />
                    },
                    {
                        path: '/index/GoodsSmallClasses',
                        key: '/index/GoodsSmallClasses',
                        component: () => <KeepaliveRoute component={SuspenseComponent(GoodsSmallClasses)} path="/index/GoodsSmallClasses" />
                    },
                    {
                        path: '/index/HeaderquarterGoods',
                        key: '/index/HeaderquarterGoods',
                        component: () => <KeepaliveRoute component={SuspenseComponent(HeaderquarterGoods)} path="/index/HeaderquarterGoods" />
                    },
                    {
                        path: '/index/CustomerInformation',
                        key: '/index/CustomerInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(CustomerInformation)} path="/index/CustomerInformation" />
                    },
                    {
                        path: '/index/DepartmentInformation',
                        key: '/index/DepartmentInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(DepartmentInformation)} path="/index/DepartmentInformation" />
                    },
                    {
                        path: '/index/StaffInformation',
                        key: '/index/StaffInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(StaffInformation)} path="/index/StaffInformation" />
                    },
                    {
                        path: '/index/WarehouseInformation',
                        key: '/index/WarehouseInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(WarehouseInformation)} path="/index/WarehouseInformation" />
                    },
                    {
                        path: '/index/CsingleProductInformation',
                        key: '/index/CsingleProductInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(CsingleProductInformation)} path="/index/CsingleProductInformation" />
                    },
                    {
                        path: '/index/CProductStructruePInformation',
                        key: '/index/CProductStructruePInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(CProductStructruePInformation)} path="/index/CProductStructruePInformation" />
                    },
                    {
                        path: '/index/CSSPPInformation',
                        key: '/index/CSSPPInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(CSSPPInformation)} path="/index/CSSPPInformation" />
                    },
                    {
                        path: '/index/CSPSInformation',
                        key: '/index/CSPSInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(CSPSInformation)} path="/index/CSPSInformation" />
                    },
                    {
                        path: '/index/DSPInformation',
                        key: '/index/DSPInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(DSPInformation)} path="/index/DSPInformation" />
                    },
                    {
                        path: '/index/DPSPInformation',
                        key: '/index/DPSPInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(DPSPInformation)} path="/index/DPSPInformation" />
                    },
                    {
                        path: '/index/DSSPInformation',
                        key: '/index/DSSPInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(DSSPInformation)} path="/index/DSSPInformation" />
                    },
                    {
                        path: '/index/DSPSPInformation',
                        key: '/index/DSPSPInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(DSPSPInformation)} path="/index/DSPSPInformation" />
                    },
                    {
                        path: '/index/BrandInformation',
                        key: '/index/BrandInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(BrandInformation)} path="/index/BrandInformation" />
                    },
                    {
                        path: '/index/GoodsCorrespondingInformation',
                        key: '/index/GoodsCorrespondingInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(GoodsCorrespondingInformation)} path="/index/GoodsCorrespondingInformation" />
                    },
                    {
                        path: '/index/GoodsRecycleInformation',
                        key: '/index/GoodsRecycleInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(GoodsRecycleInformation)} path="/index/GoodsRecycleInformation" />
                    },
                    {
                        path: '/index/InventorySynchronization',
                        key: '/index/InventorySynchronization',
                        component: () => <KeepaliveRoute component={SuspenseComponent(InventorySynchronization)} path="/index/InventorySynchronization" />
                    },
                    {
                        path: '/index/SaleOrder',
                        key: '/index/SaleOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(SaleOrder)} path="/index/SaleOrder" />
                    },
                    {
                        path: '/index/PurchaseOrder',
                        key: '/index/PurchaseOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(PurchaseOrder)} path="/index/PurchaseOrder" />
                    },
                    {
                        path: '/index/PurchaseInStorageOrder',
                        key: '/index/PurchaseInStorageOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(PurchaseInStorageOrder)} path="/index/PurchaseInStorageOrder" />
                    },
                    {
                        path: '/index/PurchaseRetrunOrder',
                        key: '/index/PurchaseRetrunOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(PurchaseRetrunOrder)} path="/index/PurchaseRetrunOrder" />
                    },
                    {
                        path: '/index/SaleDeliveryOrder',
                        key: '/index/SaleDeliveryOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(SaleDeliveryOrder)} path="/index/SaleDeliveryOrder" />
                    },
                    {
                        path: '/index/SaleSwapGoodsOrder',
                        key: '/index/SaleSwapGoodsOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(SaleSwapGoodsOrder)} path="/index/SaleSwapGoodsOrder" />
                    },
                    {
                        path: '/index/SaleReturnOrder',
                        key: '/index/SaleReturnOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(SaleReturnOrder)} path="/index/SaleReturnOrder" />
                    },
                    {
                        path: '/index/PurchaseSwapGoodsOrder',
                        key: '/index/PurchaseSwapGoodsOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(PurchaseSwapGoodsOrder)} path="/index/PurchaseSwapGoodsOrder" />
                    },
                    {
                        path: '/index/CoequalTransferOrder',
                        key: '/index/CoequalTransferOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(CoequalTransferOrder)} path="/index/CoequalTransferOrder" />
                    },
                    {
                        path: '/index/OtherInStorageOrder',
                        key: '/index/OtherInStorageOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(OtherInStorageOrder)} path="/index/OtherInStorageOrder" />
                    },
                    {
                        path: '/index/CheckOrder',
                        key: '/index/CheckOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(CheckOrder)} path="/index/CheckOrder" />
                    },
                    {
                        path: '/index/BreakageOrder',
                        key: '/index/BreakageOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(BreakageOrder)} path="/index/BreakageOrder" />
                    },
                    {
                        path: '/index/EntrustDeliveryOrder',
                        key: '/index/EntrustDeliveryOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(EntrustDeliveryOrder)} path="/index/EntrustDeliveryOrder" />
                    },
                    {
                        path: '/index/EntrustConsignmentReturnOrder',
                        key: '/index/EntrustConsignmentReturnOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(EntrustConsignmentReturnOrder)} path="/index/EntrustConsignmentReturnOrder" />
                    },
                    {
                        path: '/index/CommissionedConsignmentReceivingOrder',
                        key: '/index/CommissionedConsignmentReceivingOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(CommissionedConsignmentReceivingOrder)} path="/index/CommissionedConsignmentReceivingOrder" />
                    },
                    {
                        path: '/index/CommissionedConsignmentReturnOrder',
                        key: '/index/CommissionedConsignmentReturnOrder',
                        component: () => <KeepaliveRoute component={SuspenseComponent(CommissionedConsignmentReturnOrder)} path="/index/CommissionedConsignmentReturnOrder" />
                    },
                    {
                        path: '/index/InventoryStatistics',
                        key: '/index/InventoryStatistics',
                        component: () => <KeepaliveRoute component={SuspenseComponent(InventoryStatistics)} path="/index/InventoryStatistics" />
                    },
                    {
                        path: '/index/OperatorSetting',
                        key: '/index/OperatorSetting',
                        component: () => <KeepaliveRoute component={SuspenseComponent(OperatorSetting)} path="/index/OperatorSetting" />
                    },
                    {
                        path: '/index/RoleSetting',
                        key: '/index/RoleSetting',
                        component: () => <KeepaliveRoute component={SuspenseComponent(RoleSetting)} path="/index/RoleSetting" />
                    },
                    {
                        path: '/index/OperateLog',
                        key: '/index/OperateLog',
                        component: () => <KeepaliveRoute component={SuspenseComponent(OperateLog)} path="/index/OperateLog" />
                    },
                    {
                        path: '/index/OMAinformation',
                        key: '/index/OMAinformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(OMAinformation)} path="/index/OMAinformation" />
                    },
                    {
                        path: '/index/OMLinformation',
                        key: '/index/OMLinformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(OMLinformation)} path="/index/OMLinformation" />
                    },
                    {
                        path: '/index/OMIinformation',
                        key: '/index/OMIinformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(OMIinformation)} path="/index/OMIinformation" />
                    },
                    {
                        path: '/index/TransportInformation',
                        key: '/index/TransportInformation',
                        component: () => <KeepaliveRoute component={SuspenseComponent(TransportInformation)} path="/index/TransportInformation" />
                    },
                    {
                        path: '/index/InventoryAnalysis',
                        key: '/index/InventoryAnalysis',
                        component: () => <KeepaliveRoute component={SuspenseComponent(InventoryAnalysis)} path="/index/InventoryAnalysis" />
                    },
                    {
                        path: '/index/ServerMonitoring',
                        key: '/index/ServerMonitoring',
                        component: () => <KeepaliveRoute component={SuspenseComponent(ServerMonitoring)} path="/index/ServerMonitoring" />
                    },
                    {
                        path: '/index/ErpMonitoring',
                        key: '/index/ErpMonitoring',
                        component: () => <KeepaliveRoute component={SuspenseComponent(ErpMonitoring)} path="/index/ErpMonitoring" />
                    },
                    {
                        path: '/index/ApiMonitoring',
                        key: '/index/ApiMonitoring',
                        component: () => <KeepaliveRoute component={SuspenseComponent(ApiMonitoring)} path="/index/ApiMonitoring" />
                    },
                    {
                        path: '/index/ApiCenter',
                        key: '/index/ApiCenter',
                        component: () => <KeepaliveRoute component={SuspenseComponent(ApiCenter)} path="/index/ApiCenter" />
                    },
                    {
                        path: '*',
                        component: SuspenseComponent(NoFound)
                    },
                ]
            },
            {
                path: '/change_parssword',
                key: '/change_parssword',
                component: SuspenseComponent(ChangeParssword),
            },
            {
                path: '*',
                component: SuspenseComponent(NoFound)
            }
        ]
    }
];

export default routes;

